import React, { useState } from 'react';
import css from './CustomUserNav.module.css';
import { ReactComponent as ArrowDown } from '../../assets/downArrow.svg';
// import { ReactComponent as ArrowRight } from '../../assets/ArrowRight.svg';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';

// import Container from 'react-bootstrap/Container';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './reactSubMenu.css';
import './custumNavSlide.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { InlineTextButton } from '../Button/Button';
import { compose } from 'redux';
import { dismissMigrationNotice } from '../../ducks/user.duck';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { ReactComponent as Cross } from '../../assets/Cross.svg';
// import '@szhsin/react-menu/dist/index.css';
// import '@szhsin/react-menu/dist/transitions/slide.css';
const CustomUserNavComponent = props => {
  const { history, onDismissMigrationNotice, showNotice, currentUser } = props;
  const extId = currentUser?.attributes?.profile?.metadata?.extId;
  const [viewFullMessage, setViewFullMessage] = useState(false);
  const migrationInfoText =
    'We have migrated our service to new platform to enhance your experience. We kindly request you to update all your product lists for seamless integration with the new platform.';
  return (
    <React.Fragment>
      <div className={css.customUserNav}>
        <Menu
          menuButton={
            <MenuButton
              className={css.noOption}
              onClick={e => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration(),
                    {}
                  )
                );
              }}
            >
              Marketplace
            </MenuButton>
          }
        >
          {/* <MenuItem>New</MenuItem>
                <MenuItem>Used</MenuItem> */}
        </Menu>
        <Menu
          menuButton={
            <MenuButton
              className={css.noOption}
              onClick={e => {
                // console.log(252, e);
                const params = {
                  pub_productCategory: 'businessServices',
                };
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration(),
                    {},
                    params
                  )
                );
              }}
            >
              Business Services
            </MenuButton>
          }
        >
          {/* <MenuItem>New</MenuItem>
                <MenuItem>Used</MenuItem> */}
        </Menu>
        <Menu
          menuButton={
            <MenuButton
              className={css.noOption}
              onClick={e => {
                // console.log(252, e);
                const params = {
                  pub_productCategory: 'accommodation',
                };
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration(),
                    {},
                    params
                  )
                );
              }}
            >
              Accomodation
            </MenuButton>
          }
        >
          {/* <MenuItem>New</MenuItem>
                <MenuItem>Used</MenuItem> */}
        </Menu>
        <Menu
          menuButton={
            <MenuButton
              className={css.noOption}
              onClick={e => {
                // console.log(252, e);
                const params = {
                  pub_productCategory: 'restaurantsCafe',
                };
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration(),
                    {},
                    params
                  )
                );
              }}
            >
              Restaurant
            </MenuButton>
          }
        >
          {/* <MenuItem>New</MenuItem>
                <MenuItem>Used</MenuItem> */}
        </Menu>
        <Menu
          menuButton={
            <MenuButton className={css.optionDiv}>
              Rental <ArrowDown className={css.arrow} />
            </MenuButton>
          }
        >
          <MenuItem
            onClick={e => {
              // console.log(252, e);
              const params = {
                // pub_productCategory: 'carRentals',
                // pub_productCategory: 'rental',
                pub_productSubCategory: 'carRental',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            Car
          </MenuItem>
          <MenuItem
            onClick={e => {
              // console.log(252, e);
              const params = {
                // pub_productCategory: 'carRentals',
                // pub_productCategory: 'rental',
                pub_productSubCategory: 'bikeRental',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            Bike
          </MenuItem>
          {/* <MenuItem
            onClick={e => {
              // console.log(252, e);
              const params = {
                pub_productCategory: 'accommodation',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            Accomodation
          </MenuItem> */}

          {/* <SubMenu label="Portable">
                  <MenuItem>New</MenuItem>
                  <MenuItem>Used</MenuItem>
                </SubMenu> */}
        </Menu>
        {/* </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      </div>

      {/* message for migrated user */}
      {/* {showNotice && extId && (
        <div className={css.noticeBoard}>
          <div className={css.notice}>
            {viewFullMessage ? (
              migrationInfoText
            ) : (
              <span>{migrationInfoText}</span>
            )}

            {viewFullMessage ? (
              <button
                className={css.moreLess}
                type="button"
                onClick={() => {
                  setViewFullMessage(!viewFullMessage);
                }}
              >
                See Less
              </button>
            ) : (
              <button
                className={css.moreLess}
                type="button"
                onClick={() => {
                  setViewFullMessage(!viewFullMessage);
                }}
              >
                See More
              </button>
            )}
          </div>
          <InlineTextButton
            onClick={() => onDismissMigrationNotice()}
            className={css.dissmissBtn}
          >
            <Cross />
          </InlineTextButton>
        </div>
      )} */}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const { showNotice, currentUser } = state.user;
  return { showNotice, currentUser };
};

const mapDispatchToProps = dispatch => ({
  onDismissMigrationNotice: () => dispatch(dismissMigrationNotice()),
});

const CustomUserNav = compose(connect(mapStateToProps, mapDispatchToProps))(
  CustomUserNavComponent
);

export default CustomUserNav;
