import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink } from '../../components';

import css from './TransactionPanel.module.css';
import {
  listingType,
  rentalSubType,
  rentalType,
} from '../../marketplace-custom-config';

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_PURCHASED = 'purchased';
export const HEADING_DISPUTED = 'disputed';
export const HEADING_DECLINED = 'declined';
export const HEADING_CANCELED = 'canceled';
export const HEADING_DELIVERED = 'delivered';
export const HEADING_COMPLETED = 'completed';
export const HEADING_RECEIVED = 'received';

const createListingLink = (
  listingId,
  label,
  listingDeleted,
  searchParams = {},
  className = ''
) => {
  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink
        className={className}
        name="ListingPage"
        params={params}
        to={to}
      >
        {label.charAt(0).toUpperCase() + label.substring(1)}
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const ListingDeletedInfoMaybe = props => {
  return props.listingDeleted ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.messageDeletedListing" />
    </p>
  ) : null;
};

const HeadingCustomer = props => {
  const { className, id, values, listingDeleted } = props;
  // console.log(1525, values);

  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const HeadingCustomerWithSubtitle = props => {
  const {
    className,
    id,
    values,
    subtitleId,
    subtitleValues,
    children,
    listingDeleted,
  } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
          <span className={css.subtitle}>
            <FormattedMessage id={subtitleId} values={subtitleValues} />
          </span>
        </span>
      </h1>

      {values?.deliveryMethod === 'pickup' ? (
        <div className={css.pickupAddressMessage}>
          Message the seller for pickup address
        </div>
      ) : null}

      {children}
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const CustomerBannedInfoMaybe = props => {
  return props.isCustomerBanned ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.customerBannedStatus" />
    </p>
  ) : null;
};

const HeadingProvider = props => {
  const {
    className,
    id,
    values,
    isCustomerBanned,
    children,
    showSubTitle = false,
  } = props;

  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />

          {showSubTitle && (
            <span className={css.subtitle}>{values.customerName}.</span>
          )}
        </span>
      </h1>
      {children}
      <CustomerBannedInfoMaybe isCustomerBanned={isCustomerBanned} />
    </React.Fragment>
  );
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const PanelHeading = props => {
  const {
    className,
    rootClassName,
    panelHeadingState,
    customerName,
    providerName,
    listingId,
    listingTitle,
    listingDeleted,
    isCustomerBanned,
    transaction,
  } = props;
  const {
    attributes: {
      protectedData: {
        bookingSubType,
        emailMultiItemCount = 0,
        bookingDetails = [],
      },
    },
  } = transaction;
  const { deliveryMethod, productSubCategory, productCategory } =
    bookingDetails[0] || {};
  const isCustomer = props.transactionRole === 'customer';

  const defaultRootClassName = isCustomer ? css.headingOrder : css.headingSale;
  const titleClasses = classNames(
    rootClassName || defaultRootClassName,
    className
  );
  const listingLink = createListingLink(
    listingId,
    listingTitle,
    listingDeleted
  );
  const orderOption = bookingSubType === listingType.rental ? 'book' : 'order';
  const bookingType =
    bookingSubType === listingType.rental ? 'booking' : 'order';

  const deliveryType =
    rentalType.includes(productCategory) ||
    rentalSubType.includes(productSubCategory)
      ? 'completed'
      : 'shipped';

  switch (panelHeadingState) {
    case HEADING_ENQUIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderEnquiredTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleEnquiredTitle"
          values={{ customerName, listingLink, type: bookingType }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_PAYMENT_PENDING:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPaymentPendingTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentPendingTitle"
          values={{ customerName, listingLink, type: bookingType }}
          isCustomerBanned={isCustomerBanned}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.salePaymentPendingInfo"
              values={{ customerName }}
            />
          </p>
        </HeadingProvider>
      );
    case HEADING_PAYMENT_EXPIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.orderPaymentExpiredTitleMoreItem'
              : 'TransactionPanel.orderPaymentExpiredTitle'
          }
          values={{
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
          }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentExpiredTitle"
          values={{ customerName, listingLink, type: bookingType }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_REQUESTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderPreauthorizedTitle"
          values={{ customerName }}
          subtitleId="TransactionPanel.orderPreauthorizedSubtitle"
          subtitleValues={{ listingLink, type: orderOption }}
        >
          {!listingDeleted ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderPreauthorizedInfo"
                values={{ providerName, type: bookingType }}
              />
            </p>
          ) : null}
        </HeadingCustomerWithSubtitle>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleRequestedTitle"
          values={{ customerName, listingLink }}
        >
          {!isCustomerBanned ? (
            <p className={titleClasses}>
              <FormattedMessage
                id="TransactionPanel.saleRequestedInfo"
                values={{ customerName }}
              />
            </p>
          ) : null}
        </HeadingProvider>
      );
    case HEADING_ACCEPTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderPreauthorizedTitle"
          values={{ customerName }}
          subtitleId="TransactionPanel.orderAcceptedSubtitle"
          subtitleValues={{ listingLink }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleAcceptedTitle"
          values={{ customerName, listingLink }}
        />
      );
    case HEADING_PURCHASED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.orderPurchasedTitleMoreItem'
              : 'TransactionPanel.orderPurchasedTitle'
          }
          values={{
            customerName,
            listingLink,
            count: emailMultiItemCount,
            deliveryMethod,
          }}
          subtitleId="TransactionPanel.orderPurchasedSubTitle"
          subtitleValues={{ listingLink }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.salePurchasedTitleMoreItem'
              : 'TransactionPanel.salePurchasedTitle'
          }
          values={{
            customerName,
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
          }}
        />
      );
    case HEADING_DISPUTED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.orderDisputedTitleMoreItem'
              : 'TransactionPanel.orderDisputedTitle'
          }
          values={{ customerName, listingLink, count: emailMultiItemCount }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.saleDisputedTitleMoreItem'
              : 'TransactionPanel.saleDisputedTitle'
          }
          values={{ customerName, listingLink, count: emailMultiItemCount }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_DECLINED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderDeclinedTitle"
          values={{ customerName, listingLink, type: bookingType }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleDeclinedTitle"
          values={{ customerName, listingLink, type: bookingType }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_CANCELED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.orderCancelledTitleMoreItem'
              : 'TransactionPanel.orderCancelledTitle'
          }
          values={{
            customerName,
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
          }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.saleCancelledTitleMoreItem'
              : 'TransactionPanel.saleCancelledTitle'
          }
          values={{
            customerName,
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
          }}
        />
      );

    case HEADING_RECEIVED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.orderReceivedTitleMoreItem'
              : 'TransactionPanel.orderReceivedTitle'
          }
          values={{
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
          }}
          isCustomerBanned={isCustomerBanned}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.saleReceivedTitleMoreItem'
              : 'TransactionPanel.saleReceivedTitle'
          }
          values={{
            customerName,
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
          }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_DELIVERED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.orderDeliveredTitleMoreItem'
              : 'TransactionPanel.orderDeliveredTitle'
          }
          values={{
            providerName,
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
            deliveryType,
          }}
          isCustomerBanned={isCustomerBanned}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.saleDeliveredTitleMoreItem'
              : 'TransactionPanel.saleDeliveredTitle'
          }
          values={{
            customerName,
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
            deliveryType,
          }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_COMPLETED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.orderCompletedTitleMoreItem'
              : 'TransactionPanel.orderCompletedTitle'
          }
          values={{
            customerName,
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
          }}
          isCustomerBanned={isCustomerBanned}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id={
            emailMultiItemCount > 0
              ? 'TransactionPanel.saleCompletedTitleMoreItem'
              : 'TransactionPanel.saleCompletedTitle'
          }
          values={{
            customerName,
            listingLink,
            type: bookingType,
            count: emailMultiItemCount,
          }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    default:
      console.warn('Unknown state given to panel heading.');
      return null;
  }
};

export default PanelHeading;
