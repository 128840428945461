import React, { Component } from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import {
  createResourceLocatorString,
  pathByRouteName,
} from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Avatar,
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  ModalMissingInformation,
  NamedLink,
  TopbarDesktop,
  TopbarMobileMenu,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import css from './Topbar.module.css';
import { ReactComponent as BellIcon } from '../../assets/NotificationOn.svg';
import { ReactComponent as WishlistIcon } from '../../assets/Wishlist.svg';
import { ReactComponent as CartIcon } from '../../assets/Cart.svg';
import { ReactComponent as AddIcon } from '../../assets/Add.svg';
import { HiPlus } from 'react-icons/hi';
import CurrencyExchangeDropdown from '../CurrencyExchangeDropdown/CurrencyExchangeDropdown';

const MAX_MOBILE_SCREEN_WIDTH = 992;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({
    [modalStateParam]: 'open',
    ...parse(search),
  })}`;
  history.replace(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.replace(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { keywords, ...rest } = values || {};
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};

    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
      keywords,
    };

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        searchParams
      )
    );
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserListing,
      currentUserListingFetched,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      customerNotificationCount,
      currentUserAsCustomerRtqNotificationCount,
      currentUserAsProviderRtqNotificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      cartCount,
      showSearchInMobile,
    } = this.props;
    const { search: locSearch, pathname, hash } = location;
    const searchedValue = parse(locSearch);
    // console.log(7665, searchedValue);
    const currentLocation = `${pathname}${locSearch}${hash}`; 
    const { mobilemenu, mobilesearch, address, origin, bounds } = parse(
      location.search,
      {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      }
    );

    // const notificationDot =
    //   notificationCount > 0 ? <div className={css.notificationDot} /> : null;
    const totalNotificationCount =
      notificationCount +
      customerNotificationCount +
      currentUserAsProviderRtqNotificationCount +
      currentUserAsCustomerRtqNotificationCount;

    const notificationDot =
      totalNotificationCount > 0 ? (
        <div className={css.notificationDot}>
          {totalNotificationCount > 9 ? '9+' : totalNotificationCount}
        </div>
      ) : null;

    // const cartNotificationDot =
    //   cartCount > 0 ? <div className={css.notificationDot} /> : null;

    // cartCount = 99999;
    const cartNotificationDot =
      cartCount > 0 ? (
        <React.Fragment>
          <div className={css.notificationDot}>
            {cartCount > 9 ? '9+' : cartCount}
          </div>
          {/* <div className={css.notificationDot} /> */}
        </React.Fragment>
      ) : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUserListing={currentUserListing}
        currentUserListingFetched={currentUserListingFetched}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={totalNotificationCount}
        customerNotificationCount={customerNotificationCount}
        currentPage={currentPage}
        history={this.props.history}
      />
    );

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
      keywordSearch: searchedValue?.keywords ? searchedValue?.keywords : null,
    };

    const classes = classNames(rootClassName || css.root, className);
    // const showSearchInMobile =
    //   location.pathname === '/' ||
    //   location.pathname === `/s` ||
    //   location.pathname === '/inbox' ||
    //   location.pathname === '/l' ||
    //   location.pathname === '/listings' ||
    //   location.pathname === '/favourite' ||
    //   location.pathname === '/carts' ||
    //   location.pathname === '/u' ||
    //   location.pathname === '/order' ||
    //   location.pathname === '/sale';
    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div
          className={classNames(
            mobileRootClassName || css.container,
            mobileClassName
          )}
        >
          {/* <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button> */}
          <NamedLink
            className={css.home}
            name="LandingPage"
            title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
          >
            <Logo format="mobile" />
          </NamedLink>

          <div className={css.topbarRightDiv}>
            <div className={css.currencyDropdown}>
              <CurrencyExchangeDropdown />
            </div>
            <div className={css.createNewListingLink}>
              {isAuthenticated ? null : (
                // <NamedLink name="NewListingPage">
                //   <AddIcon className={css.svgIcons} />
                //   {/* <HiPlus /> */}
                // </NamedLink>
                <NamedLink name="LoginPage">
                  <span className={css.svgIcons}>Sign In</span>
                  {/* <HiPlus /> */}
                </NamedLink>
              )}
            </div>
            <div className={css.rightIcons}>
              {/* <BellIcon className={css.svgIcons} /> */}

              <NamedLink name="FavouritesPage">
                <WishlistIcon className={css.svgIcons} />
              </NamedLink>

              <NamedLink
                name="CartPage"
                className={css.cartIcon}
                to={{ state: { from: currentLocation } }}
              >
                <CartIcon className={css.svgIcons} />
                {cartNotificationDot}
              </NamedLink>

              {/* <NamedLink name="ProfileSettingsPage" className={css.profileIcon}>
                <Avatar
                  className={css.avatar}
                  user={currentUser}
                  disableProfileLink
                />
              </NamedLink> */}
              {/* <MenuIcon className={css.menuIcon} /> */}
            </div>
          </div>
          {/* <Button
            rootClassName={css.searchMenu}
            onClick={this.handleMobileSearchOpen}
            title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
          >
            <SearchIcon className={css.searchMenuIcon} />
          </Button> */}
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUserListing={currentUserListing}
            currentUserListingFetched={currentUserListingFetched}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={totalNotificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            history={this.props.history}
            cartCount={cartCount}
            location={location}
            searchedValue={searchedValue}
          />
        </div>
        {showSearchInMobile ? (
          <div className={css.mobileSearchContainer}>
            <Button
              rootClassName={css.menu}
              onClick={this.handleMobileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <MenuIcon className={css.menuIcon} />
              {notificationDot}
            </Button>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              className={css.mobileSearchForm}
              history={this.props.history}
              searchedValue={searchedValue}
            />
          </div>
        ) : null}

        <Modal
          id="TopbarMobileMenu"
          scrollLayerClassName={css.topBarMobileScrollLayer}
          containerClassName={css.topBarMobileContainer}
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
