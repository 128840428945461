import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { IoMdInformationCircle } from 'react-icons/io';
import arrayMutators from 'final-form-arrays';

import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  moneySubUnitAmountAtLeast,
  numberAtLeast,
  requiredShippingPrice,
  requiredInternatioanlShippingPrice,
  hsCodeRequired,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckbox,
  FieldCurrencyInput,
  Modal,
  ExternalLink,
  FieldCheckboxGroup,
  IconSpinner,
  ProductHsCode,
  InlineTextButton,
} from '../../components';
import Select, { components } from 'react-select';
import { ReactComponent as ArrowDown } from '../../assets/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/ArrowUp.svg';
import { ReactComponent as BoxUnchecked } from '../../assets/BoxUnchecked.svg';
import { ReactComponent as BoxChecked } from '../../assets/BoxChecked.svg';
import { ReactComponent as RadioUnchecked } from '../../assets/RadioUnchecked.svg';
import { ReactComponent as RadioChecked } from '../../assets/RadioChecked.svg';
import css from './EditListingDescriptionForm.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import {
  categoriesWithListingType,
  categoriesWithSubCategories,
  bathroomBedroomNumbers,
  shoeSizes,
  oneItem,
  moreItems,
  deliveryMethodOptions,
  shippingChargeOptions,
  allowedPricingTypeOptions,
  AUTO_STOCK,
  ONE_SIZE,
  shippingTypes,
  allowedCurrencyOptions,
  returnPolicyOptions,
} from '../../marketplace-custom-config';
import { createResourceLocatorString } from '../../util/routes';
import { withRouter } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  convertUnitToSubUnit,
  formatMoney,
  unitDivisor,
} from '../../util/currency';
import { includes, isArray, isEmpty, isNumber } from 'lodash';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { PriceDataWithDifferentCurrency } from '../../util/PriceDataWithDifferentCurrency';

const { Money } = sdkTypes;

const ALL_ITEMS = [
  'gifts',
  'household',
  'fabrics',
  'bedding',
  'homeDecor',
  'books',
  'shoes',
  'clothing',
  'jewellery',
  'beauty',
  'traditional',
  'foodDrinks',
  'furniture',
  'pets',
  'houseHold',
  'art',
  'bagAndPurse',
  'rental',
];
const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const convertToAUD = (value, currencyCode, exchangeRates) => {
  if (!value || isNaN(value)) return '0.00';

  const exchangeRate = exchangeRates[currencyCode];
  if (!exchangeRate) return '0.00'; // Return 0 if the currency code is not found

  return (parseFloat(value) / exchangeRate).toFixed(2); // Convert and format to 2 decimal places
};
const CARTYPES = ['Standard', 'Convertibles', 'Luxury', 'Vans', 'Suvs'];
const SHIPPINGCHARGETYPE = ['domestic', 'interNational'];

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 200;
export const DropdownIndicator = ({ children, ...props }) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? (
      <ArrowUp
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    ) : (
      <ArrowDown
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    )}
  </components.DropdownIndicator>
);

export const ClearIndicator = ({ children, ...props }) => (
  <components.ClearIndicator {...props}>
    <IoMdClose className={css.selectClearBtn} />
  </components.ClearIndicator>
);
export const Menu = ({ children, ...props }) => (
  <components.Menu
    {...props}
    className={classNames(css.menu, {
      // [css.noValueMenu]: props.selectProps.id === 'country' || !props.hasValue,
    })}
  >
    {children}
  </components.Menu>
);
export const MenuForReturnPolicy = ({ children, ...props }) => (
  <components.Menu
    {...props}
    className={classNames(css.menuForReturnPolicy, {
      // [css.noValueMenu]: props.selectProps.id === 'country' || !props.hasValue,
    })}
  >
    {children}
  </components.Menu>
);

export const getValueContainer = (
  placeholder,
  defaultPlaceholder,
  callback,
  className,
  valueSpanClassName
) => ({ children, ...props }) => {
  if (typeof callback === 'function') callback(props.selectProps.menuIsOpen);

  return (
    <components.ValueContainer {...props}>
      <div
        className={classNames(css.valueContainer, className, {
          [css.multiValueContainer]: props.isMulti,
        })}
      >
        <span
          className={classNames(css.placeholder, {
            [css.selectedPlaceholder]:
              props.hasValue || props.selectProps.menuIsOpen,
            // [css.selectedSCPlaceholder]:
            //   props.hasValue || props.selectProps.menuIsOpen || values.productSubCategory,

            [css.emptyPlaceholder]: props.isMulti && !props.hasValue,
            [valueSpanClassName]:
              !!valueSpanClassName &&
              (props.hasValue || props.selectProps.menuIsOpen),
          })}
        >
          {!props.hasValue ? defaultPlaceholder : placeholder}
        </span>
        <div
          className={classNames(css.value, {
            [css.nonEmptyValue]: props.hasValue,
            [css.blurredValue]: props.hasValue && props.selectProps.menuIsOpen,
          })}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};
const filterConfig = config.custom.filters;

const productCategoryOptions = findOptionsForSelectFilter(
  'productCategory',
  filterConfig
);

const foodDrinksSubCategoryOptions = findOptionsForSelectFilter(
  'foodDrinksSubCategory',
  filterConfig
);
const traditionalSubCategoryOptions = findOptionsForSelectFilter(
  'traditionalSubCategory',
  filterConfig
);
const clothingSubCategoryOptions = findOptionsForSelectFilter(
  'clothingSubCategory',
  filterConfig
);
const jewellerySubCategoryOptions = findOptionsForSelectFilter(
  'jewellerySubCategory',
  filterConfig
);
const beautySubCategoryOptions = findOptionsForSelectFilter(
  'beautySubCategory',
  filterConfig
);

const booksSubCategoryOptions = findOptionsForSelectFilter(
  'booksSubCategory',
  filterConfig
);
const homeDecorSubCategoryOptions = findOptionsForSelectFilter(
  'homeDecorSubCategory',
  filterConfig
);
const furnitureSubCategoryOptions = findOptionsForSelectFilter(
  'furnitureSubCategory',
  filterConfig
);
const businessServicesSubCategoryOptions = findOptionsForSelectFilter(
  'businessServicesSubCategory',
  filterConfig
);
const restaurantCafeSubCategoryOptions = findOptionsForSelectFilter(
  'restaurantCafeSubCategory',
  filterConfig
);

const carTypeOptions = findOptionsForSelectFilter('carType', filterConfig);
const shoesSizeOptions = shoeSizes;

const listingTypes = findOptionsForSelectFilter('listingType', filterConfig);
const accommodationFeaturesOPtions = findOptionsForSelectFilter(
  'accommodationFeatures',
  filterConfig
);

const petsSubCategoryOPtions = findOptionsForSelectFilter(
  'petsSubCategory',
  filterConfig
);

const householdSubCategoryOptions = findOptionsForSelectFilter(
  'householdSubCategory',
  filterConfig
);
const artSubCategoryOptions = findOptionsForSelectFilter(
  'artSubCategory',
  filterConfig
);
const rentalSubCategoryOptions = findOptionsForSelectFilter(
  'rentalSubCategory',
  filterConfig
);
// console.log(3332, rentalSubCategoryOptions);
const clothingSizeOptions = findOptionsForSelectFilter(
  'clothingSize',
  filterConfig
).reduce((pre, option) => {
  pre[option.key] = option.label;
  return pre;
}, {});

const shoeSizeOptions = shoesSizeOptions.reduce((pre, option) => {
  pre[option.key] = option.label_UK;
  return pre;
}, {});

const sizeOptions = Object.assign(clothingSizeOptions, shoeSizeOptions);

const buffet = 'buffet';
const foodDrinks = 'foodDrinks';
const traditional = 'traditional';
const clothing = 'clothing';
const jewellery = 'jewellery';
const beauty = 'beauty';
const books = 'books';
const homeDecor = 'homeDecor';
const furniture = 'furniture';
const businessServices = 'businessServices';
const restaurantsCafe = 'restaurantsCafe';
const accommodation = 'accommodation';
const pets = 'pets';
const houseHold = 'household';
const art = 'art';
const rental = 'rental';

const itemTypes = [oneItem, moreItems];
const buffetTypes = [restaurantsCafe, buffet];
const nonStockTypesShipping = [restaurantsCafe];

const subCategoryOptions = {
  [foodDrinks]: foodDrinksSubCategoryOptions,
  [traditional]: traditionalSubCategoryOptions,
  [clothing]: clothingSubCategoryOptions,
  [jewellery]: jewellerySubCategoryOptions,
  [beauty]: beautySubCategoryOptions,
  [books]: booksSubCategoryOptions,
  [homeDecor]: homeDecorSubCategoryOptions,
  [furniture]: furnitureSubCategoryOptions,
  [businessServices]: businessServicesSubCategoryOptions,
  [restaurantsCafe]: restaurantCafeSubCategoryOptions,
  [accommodation]: accommodationFeaturesOPtions,
  [pets]: petsSubCategoryOPtions,
  [houseHold]: householdSubCategoryOptions,
  [art]: artSubCategoryOptions,
  [rental]: rentalSubCategoryOptions,
};

const getInitialSubcategory = (category, subCategory) => {
  // console.log(999, category, subCategory);
  const options = subCategoryOptions[category] || [];

  const categoryObj = options.find(cate => cate.key === subCategory);
  return categoryObj;
};
const shippingOptions = Object.values(shippingTypes);

const isStockFilled = values => {
  const {
    productCategory,
    clothingSize,
    shoeSize,
    clothingStockPerSize,
    shoeStockPerSize,
    stock,
  } = values;
  if ((!categoriesWithListingType, includes(productCategory))) return false;
  if (nonStockTypesShipping.includes(productCategory)) return true;
  const sizeArr =
    clothingSize && clothingSize.length > 0
      ? clothingSize
      : shoeSize && shoeSize.length > 0
      ? shoeSize
      : [ONE_SIZE];
  const stockPerSize =
    clothingStockPerSize && !isEmpty(clothingStockPerSize)
      ? clothingStockPerSize
      : shoeStockPerSize && !isEmpty(shoeStockPerSize)
      ? shoeStockPerSize
      : { [ONE_SIZE]: stock };
  const isAllStockFiled = sizeArr.every(
    sizeName => parseInt(stockPerSize[sizeName], 10) > 0
  );
  return isAllStockFiled;
};

const isAllParcelDetailsFilled = values => {
  const {
    productCategory,
    clothingSize,
    shoeSize,
    clothingStockPerSize,
    shoeStockPerSize,
    stock,
    parcelDetails,
    listingType,
  } = values;
  if ((!categoriesWithListingType, includes(productCategory))) return false;
  if (nonStockTypesShipping.includes(productCategory)) return true;
  const sizeArr =
    clothingSize && clothingSize.length > 0
      ? clothingSize
      : shoeSize && shoeSize.length > 0
      ? shoeSize
      : [ONE_SIZE];
  const stockPerSize =
    clothingStockPerSize && !isEmpty(clothingStockPerSize)
      ? clothingStockPerSize
      : shoeStockPerSize && !isEmpty(shoeStockPerSize)
      ? shoeStockPerSize
      : { [ONE_SIZE]: stock };
  if (isEmpty(parcelDetails)) return false;

  const isAllStockFiled = sizeArr.every(sizeName => {
    const sizeDetails = parcelDetails[sizeName] || [];

    if (
      sizeDetails === undefined ||
      sizeDetails === null ||
      sizeDetails.length === 0
    )
      return false;
    const sizeQty = sizeDetails.reduce(
      (pre, obj, inx) =>
        listingType === oneItem
          ? inx === 0
            ? pre + obj?.qty
            : pre
          : pre + obj?.qty,
      0
    );
    const maxStockPerSize = parseInt(stockPerSize[sizeName]);
    if (listingType === oneItem && sizeQty > 0) return true;
    else if (sizeQty !== maxStockPerSize || isNaN(maxStockPerSize))
      return false;
    else return true;
  });
  return isAllStockFiled;
};

const ParcelDetailsComponent = props => {
  const { values, className, intl, form } = props;
  const {
    deliveryMethod,
    clothingSize,
    shoeSize,
    clothingStockPerSize,
    shoeStockPerSize,
    stock,
    shippingType,
    parcelDetails = {},
    listingType,
    sameDetailsForAll = ['1'],
    productHscode,
  } = values;

  const isShippingTypeDm =
    deliveryMethod.includes(deliveryMethodOptions.shipping.value) &&
    shippingType === deliveryMethodOptions.automaticShipping.value;
  if (!isShippingTypeDm) return null;
  const sizeArr =
    clothingSize && clothingSize.length > 0
      ? clothingSize
      : shoeSize && shoeSize.length > 0
      ? shoeSize
      : [ONE_SIZE];
  const hasSameDetaillForAllSizes =
    sameDetailsForAll && sameDetailsForAll.length > 0 && sameDetailsForAll[0];
  let parcelDetailsHeading = 'Parcel details';
  if (!hasSameDetaillForAllSizes && clothingSize && clothingSize.length > 0) {
    parcelDetailsHeading += ' for cloth size';
  } else if (!hasSameDetaillForAllSizes && shoeSize && shoeSize.length > 0) {
    parcelDetailsHeading += ' for shoe size ';
  }
  const stockPerSize =
    clothingStockPerSize && !isEmpty(clothingStockPerSize)
      ? clothingStockPerSize
      : shoeStockPerSize && !isEmpty(shoeStockPerSize)
      ? shoeStockPerSize
      : { [ONE_SIZE]: stock };

  const quantityPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.quantityPlaceholder',
  });
  const quantityLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.quantityLabel',
  });
  const quantityRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.quantityRequired',
  });
  const quantityRequired = required(quantityRequiredMessage);

  const wghtPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.wghtPlaceholder',
  });
  const wghtLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.wghtLabel',
  });
  const wghtRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.wghtRequired',
  });
  const wghtRequired = required(wghtRequiredMessage);

  const widthPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.widthPlaceholder',
  });
  const widthLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.widthLabel',
  });
  const widthRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.widthRequired',
  });
  const widthRequired = required(widthRequiredMessage);

  const lengthPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.lengthPlaceholder',
  });
  const lengthLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.lengthLabel',
  });
  const lengthRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.lengthRequired',
  });
  const lengthRequired = required(lengthRequiredMessage);

  const depthPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.depthPlaceholder',
  });
  const depthLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.depthLabel',
  });
  const depthRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.parcelDetails.depthRequired',
  });
  const depthRequired = required(depthRequiredMessage);

  const digitRegex = /^[1-9]{1}[0-9]*$/;
  const fractionRegex = /^([0-9]+|[0-9]+\.[0-9]{0,2})$/;
  const classes = classNames(css.shippingParent, className);

  const handleArrElementRemove = (sizeName, callFormChange = false) => {
    if (parcelDetails[sizeName].length > 1) {
      parcelDetails[sizeName].pop();
      if (callFormChange) {
        const newSize = parcelDetails[sizeName].filter(
          size => typeof size === 'object' && size.constructor === Object
        );
        parcelDetails[sizeName] = newSize;
        form.change('parcelDetails', { ...parcelDetails });
      }
    }
  };
  const handleArrElementAdd = (sizeName, callFormChange = false) => {
    const preParecelDetails = parcelDetails[sizeName] || [];
    parcelDetails[sizeName] =
      preParecelDetails.length === 0 ? [{}, {}] : [...preParecelDetails, {}];
    if (callFormChange) {
      const newSize = parcelDetails[sizeName].filter(
        size => typeof size === 'object' && size.constructor === Object
      );
      parcelDetails[sizeName] = newSize;
      form.change('parcelDetails', { ...parcelDetails });
    }
  };
  const handleQtyChange = (strValue, indx, name, sizeName) => {
    const {
      values: { parcelDetails },
    } = form.getState();
    const preQtyValueArr = parcelDetails?.[sizeName] || [];
    const maxStockPerSize = parseInt(stockPerSize[sizeName]);
    if (isNaN(maxStockPerSize)) return;
    // const preQtyValue = preQtyValueArr.reduce(
    //   (pre, obj, rIndx) => (rIndx === indx ? pre : pre + (obj.qty || 0)),
    //   0
    // );
    // const remainingQty = maxStockPerSize - preQtyValue;
    const remainingQty = maxStockPerSize;
    const isValidValue = digitRegex.test(strValue);
    if (!isValidValue) {
      form.change(name, undefined);
      return;
    }
    const intValue = parseInt(strValue, 10);
    if (isNaN(intValue)) return;
    form.change(name, Math.min(intValue, remainingQty));

    // const lastIndx = preQtyValueArr.length;
    // if (intValue < remainingQty) {
    //   if (lastIndx > 0) {
    //     const lastQtyValue = parcelDetails[sizeName][lastIndx - 1];
    //     if (!isEmpty(lastQtyValue)) {
    //       const newName = `parcelDetails.${sizeName}.${lastIndx}`;
    //       form.change(newName, {});
    //     } else if (lastQtyValue === undefined || lastQtyValue === null) {
    //       const newName = `parcelDetails.${sizeName}.${lastIndx - 1}`;
    //       form.change(newName, {});
    //     }
    //   } else {
    //     const newName = `parcelDetails.${sizeName}.${lastIndx + 1}`;
    //     form.change(newName, {});
    //   }
    // } else {
    //   if (lastIndx > 1) {
    //     const lastQtyValue = parcelDetails[sizeName][lastIndx - 1];
    //     if (
    //       isEmpty(lastQtyValue) ||
    //       lastQtyValue === undefined ||
    //       lastQtyValue === null
    //     ) {
    //       handleArrElementRemove(sizeName, false);
    //     }
    //   }
    // }
    // form.change(name, Math.min(intValue, remainingQty));
  };

  const handleDimChange = (e, name, sizeName, indx, returnAsNumber = false) => {
    const value = e.target.value;
    if (value) {
      if (fractionRegex.test(value)) {
        if (returnAsNumber) {
          const numValue = parseFloat(value);
          form.change(name, numValue > 0 ? parseFloat(value) : undefined);
          return;
        }
        form.change(name, value);
      }
    } else {
      form.change(name, undefined);
    }

    if (
      listingType === oneItem &&
      typeof parcelDetails?.[sizeName]?.[indx]?.qty === 'undefined'
    ) {
      const qtyName = `parcelDetails.${sizeName}.${indx}.qty`;
      form.change(qtyName, 1);
    }
  };
  const handleFormBlur = name => {
    form.focus(name);
    setTimeout(() => form.blur(name), 100);
  };

  const handleDimChangeForAllSize = (e, name, returnAsNumber = false) => {
    const value = e.target.value;
    const targetName = e.target.name;
    if (value) {
      if (fractionRegex.test(value)) {
        if (returnAsNumber) {
          const numValue = parseFloat(value);
          const finalValue = numValue > 0 ? parseFloat(value) : undefined;
          const nameArr = sizeArr.map(sizeName => {
            const rootName = `parcelDetails.${sizeName}`;
            const sizeParcelDetailName = `${rootName}.0`;
            const parcelName = `${sizeParcelDetailName}.${name}`;
            return parcelName;
          });
          form.batch(() => {
            nameArr.map(n => {
              form.change(n, finalValue);
            });
            form.change(targetName, finalValue);
          });
          return;
        }
        form.change(targetName, value);
      }
    } else {
      form.change(targetName, undefined);
    }
  };

  const isValidAutomaticShipping =
    typeof values.automaticShippingOption !== 'undefined' &&
    values.automaticShippingOption.length &&
    productHscode &&
    productHscode.desc &&
    productHscode.code;
  const hasInternationalShipping =
    isValidAutomaticShipping &&
    values.automaticShippingOption.includes(shippingTypes.international.value);

  return (
    <div className={classes}>
      <div>
        <div className={css.dmDiv}>
          <FormattedMessage id="EditListingDescriptionForm.parcelDetails.shippingType" />
        </div>{' '}
        <div className={css.ShippingTypeMain}>
          {shippingOptions.map(option => (
            <FieldCheckbox
              id={`automaticShippingOption-${option.value}`}
              name="automaticShippingOption"
              value={option.value}
              key={option.value}
              label={option.label}
              customIcon={true}
            />
          ))}
        </div>
      </div>
      <ProductHsCode values={values} form={form} intl={intl} />
      {/* {hasInternationalShipping ? (
        
      ) : null} */}
      {isValidAutomaticShipping ? (
        <div className={css.parcelDetailsParent}>
          {hasSameDetaillForAllSizes ? (
            <div className={css.detailsWrapper}>
              <div className={css.sizeHeading}>
                <span className={css.spanText}>{parcelDetailsHeading}</span>
                <FieldCheckbox
                  id="sameDetailsForAll"
                  name="sameDetailsForAll"
                  value={'1'}
                  key={'1'}
                  label={'Same details for all sizes'}
                  customIcon={true}
                />
              </div>
              <div>
                <FieldTextInput
                  id={'parcelLength'}
                  name={'parcelLength'}
                  className={classNames(css.textInput)}
                  type="text"
                  label={lengthLabel}
                  placeholder={lengthPlaceholder}
                  validate={lengthRequired}
                  onChange={e => handleDimChangeForAllSize(e, 'lngth')}
                  onBlur={e => {
                    handleDimChangeForAllSize(e, 'lngth', true);
                    handleFormBlur('lngth');
                  }}
                />
                <FieldTextInput
                  id={'parcelWidth'}
                  name={'parcelWidth'}
                  className={classNames(css.textInput)}
                  type="text"
                  label={widthLabel}
                  placeholder={widthPlaceholder}
                  validate={widthRequired}
                  onChange={e => handleDimChangeForAllSize(e, 'width')}
                  onBlur={e => {
                    handleDimChangeForAllSize(e, 'width', true);
                    handleFormBlur('width');
                  }}
                />
                <FieldTextInput
                  id={'parcelDepth'}
                  name={'parcelDepth'}
                  className={classNames(css.textInput)}
                  type="text"
                  label={depthLabel}
                  placeholder={depthPlaceholder}
                  validate={depthRequired}
                  onChange={e => handleDimChangeForAllSize(e, 'depth')}
                  onBlur={e => {
                    handleDimChangeForAllSize(e, 'depth', true);
                    handleFormBlur('depth');
                  }}
                />
                <FieldTextInput
                  id={'parcelWght'}
                  name={'parcelWght'}
                  className={classNames(css.textInput)}
                  type="text"
                  label={wghtLabel}
                  placeholder={wghtPlaceholder}
                  validate={wghtRequired}
                  onChange={e => handleDimChangeForAllSize(e, 'wght')}
                  onBlur={e => {
                    handleDimChangeForAllSize(e, 'wght', true);
                    handleFormBlur('wght');
                  }}
                />
              </div>
            </div>
          ) : (
            sizeArr.map((sizeName, sizeIndx) => {
              const rootName = `parcelDetails.${sizeName}`;
              const preParecelDetails = parcelDetails[sizeName] || [{}];
              const modifiedParcelDetails =
                listingType === oneItem
                  ? preParecelDetails.slice(0, 1)
                  : preParecelDetails;
              return modifiedParcelDetails.map((sizeParecelDetails, indx) => {
                const qtyValue = (sizeParecelDetails || {}).qty ?? 0;
                const sizeParcelDetailName = `${rootName}.${indx}`;
                const qtyName = `${sizeParcelDetailName}.qty`;
                const wghtName = `${sizeParcelDetailName}.wght`;
                const widthName = `${sizeParcelDetailName}.width`;
                const lengthName = `${sizeParcelDetailName}.lngth`;
                const depthName = `${sizeParcelDetailName}.depth`;
                const showQtyDetails = listingType === moreItems && false;
                const showParecelDetails = showQtyDetails ? qtyValue > 0 : true;
                return (
                  <div
                    className={css.detailsWrapper}
                    key={`${sizeName}-${indx}`}
                  >
                    {indx > 0 && showQtyDetails ? (
                      <button
                        className={css.Removebtn}
                        type="button"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleArrElementRemove(sizeName, true);
                        }}
                      >
                        {' '}
                        - Remove Details
                      </button>
                    ) : null}

                    <div className={css.sizeQty}>
                      <div className={css.sizeHeading}>
                        {sizeName !== ONE_SIZE && indx === 0 ? (
                          <span className={css.spanText}>
                            {parcelDetailsHeading} {sizeOptions[sizeName]}
                          </span>
                        ) : (
                          <span className={css.spanText}>
                            {parcelDetailsHeading}
                          </span>
                        )}
                        {sizeIndx === 0 ? (
                          <FieldCheckbox
                            id="sameDetailsForAll-0"
                            name="sameDetailsForAll"
                            value={'1'}
                            key={'1'}
                            label={'Same details for all sizes'}
                            customIcon={true}
                          />
                        ) : null}
                      </div>
                      {showQtyDetails ? (
                        <span>
                          <FieldTextInput
                            id={qtyName}
                            name={qtyName}
                            className={classNames(css.textInput)}
                            type="text"
                            label={quantityLabel}
                            placeholder={quantityPlaceholder}
                            validate={quantityRequired}
                            onChange={e =>
                              handleQtyChange(
                                e.target.value,
                                indx,
                                qtyName,
                                sizeName
                              )
                            }
                          />
                        </span>
                      ) : null}
                    </div>
                    {showParecelDetails && isValidAutomaticShipping ? (
                      <div>
                        <FieldTextInput
                          id={lengthName}
                          name={lengthName}
                          className={classNames(css.textInput)}
                          type="text"
                          label={lengthLabel}
                          placeholder={lengthPlaceholder}
                          validate={lengthRequired}
                          onChange={e =>
                            handleDimChange(e, lengthName, sizeName, indx)
                          }
                          onBlur={e => {
                            handleDimChange(
                              e,
                              lengthName,
                              sizeName,
                              indx,
                              true
                            );
                            handleFormBlur(lengthName);
                          }}
                        />
                        <FieldTextInput
                          id={widthName}
                          name={widthName}
                          className={classNames(css.textInput)}
                          type="text"
                          label={widthLabel}
                          placeholder={widthPlaceholder}
                          validate={widthRequired}
                          onChange={e =>
                            handleDimChange(e, widthName, sizeName, indx)
                          }
                          onBlur={e => {
                            handleDimChange(e, widthName, sizeName, indx, true);
                            handleFormBlur(widthName);
                          }}
                        />
                        <FieldTextInput
                          id={depthName}
                          name={depthName}
                          className={classNames(css.textInput)}
                          type="text"
                          label={depthLabel}
                          placeholder={depthPlaceholder}
                          validate={depthRequired}
                          onChange={e =>
                            handleDimChange(e, depthName, sizeName, indx)
                          }
                          onBlur={e => {
                            handleDimChange(e, depthName, sizeName, indx, true);
                            handleFormBlur(depthName);
                          }}
                        />
                        <FieldTextInput
                          id={wghtName}
                          name={wghtName}
                          className={classNames(css.textInput)}
                          type="text"
                          label={wghtLabel}
                          placeholder={wghtPlaceholder}
                          validate={wghtRequired}
                          onChange={e =>
                            handleDimChange(e, wghtName, sizeName, indx)
                          }
                          onBlur={e => {
                            handleDimChange(e, wghtName, sizeName, indx, true);
                            handleFormBlur(wghtName);
                          }}
                        />
                      </div>
                    ) : null}
                    {indx === modifiedParcelDetails.length - 1 &&
                    showQtyDetails ? (
                      <button
                        className={css.AddNewBtn}
                        type="button"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleArrElementAdd(sizeName, true);
                        }}
                      >
                        Add new details
                      </button>
                    ) : null}
                  </div>
                );
              });
            })
          )}
        </div>
      ) : null}
    </div>
  );
};

const EditListingDescriptionFormComponent = props => {
  const [currentFormValues, setCurrentFormValues] = useState(
    props.initialValues
  );
  const [showCourierPickupInfoModal, setShowCourierPickupInfoModal] = useState(
    false
  );
  const [isprocessingFeeModalOpen, setIsProcessingFeeModalOpen] = useState(
    false
  );
  const showCourierPickupInfoModalStatus = formValues => {
    setCurrentFormValues(formValues);
    setShowCourierPickupInfoModal(true);
  };

  const showProcessingFeeModalOpen = formValues => {
    setCurrentFormValues(formValues);
    setIsProcessingFeeModalOpen(true);
  };

  return (
    <React.Fragment>
      <FinalForm
        {...props}
        initialValues={currentFormValues}
        mutators={{ ...arrayMutators }}
        setShowCourierPickupInfoModal={showCourierPickupInfoModalStatus}
        setIsProcessingFeeModalOpen={showProcessingFeeModalOpen}
        render={formRenderProps => {
          const {
            certificateOptions,
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            filterConfig,
            form,
            initialValues,
            values,
            history,
            touched,
            errors,
            active,
            customerEmail,
            isCustomQuote,
            setShowCourierPickupInfoModal,
            setIsProcessingFeeModalOpen,
            isReady,
            onCurrencyChange,
            selectedCurrencyExchangeCode,
            selectedCurrency,
            currencyExchangeCode,
          } = formRenderProps;
          console.log(2224, currencyExchangeCode);
          const currencyExchnageCodeCustom = {
            AUD: 1,
            NZD: 1.10808,
            USD: 0.630342,
            EUR: 0.601495,
            FJD: 1.451457,
            WST: 1.779054,
            TOP: 1.517659,
            SBD: 5.379336,
            PGK: 2.545563,
          };

          useEffect(() => {
            setPricingTypeOption(
              getPricingTypeOption(
                values.productCategory,
                values.productSubCategory,
                values.listingType
              )
            );
          }, []);

          const [categoryErr, setCategoryErr] = useState(false);

          const titleMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.title',
          });
          const titlePlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.titlePlaceholder',
          });
          const titleRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.titleRequired',
          });
          const carModelRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.carModelRequired',
          });
          const maxLengthMessage = intl.formatMessage(
            {
              id: 'EditListingDescriptionForm.maxLength',
            },
            {
              maxLength: TITLE_MAX_LENGTH,
            }
          );

          const descriptionMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.description',
          });
          const descriptionPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.descriptionPlaceholder',
          });
          const maxLength60Message = maxLength(
            maxLengthMessage,
            TITLE_MAX_LENGTH
          );

          const maxLength200Message = maxLength(
            'Maximum character count should be 200.',
            DESCRIPTION_MAX_LENGTH
          );
          const descriptionRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.descriptionRequired',
          });

          const {
            updateListingError,
            createListingDraftError,
            showListingsError,
          } = fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          const categoryDisabeld =
            (categoriesWithSubCategories.includes(values?.productCategory) &&
            values.productCategory !== 'businessServices'
              ? !!(values.productSubCategory && values.listingType)
                ? false
                : true
              : false) ||
            (values.productCategory === 'shoes'
              ? !!values?.listingType
                ? false
                : true
              : false) ||
            (values.productCategory === 'clothing'
              ? !!(
                  values?.clothingSize?.length !== 0 &&
                  values?.productSubCategory &&
                  values?.listingType
                )
                ? false
                : true
              : false) ||
            (values.productCategory === 'businessServices'
              ? !!values?.productSubCategory
                ? false
                : true
              : false) ||
            (values.productCategory === 'restaurantsCafe'
              ? !!(
                  (
                    values?.dinningType?.length !== 0 &&
                    values?.productSubCategory
                  )
                  // &&
                  //   values?.listingType
                )
                ? false
                : true
              : false) ||
            (values.productCategory === 'accommodation'
              ? !!(
                  values?.accommodationFeatures?.length !== 0 &&
                  values?.bathroomCount &&
                  values?.bedroomCount
                )
                ? false
                : true
              : false) ||
            (categoriesWithListingType.includes(values?.productCategory)
              ? !!values?.listingType
                ? false
                : true
              : false);

          const {
            deliveryMethod,

            shippingChargeCustom,
            internationalShippingChargeCustom,
            automaticShippingOption = [],
            shippingChargeMethod,
            productHscode,
          } = values;
          const {
            shippingType = (deliveryMethod || []).includes(
              deliveryMethodOptions.shipping.value
            )
              ? deliveryMethodOptions.manualShipping.value
              : undefined,
          } = values;
          const classes = classNames(css.root, className);
          const submitReady = isReady;
          // (updated && pristine) || ready;
          const submitInProgress = updateInProgress;

          // const submitInProgress = loading;
          const showDeliveryMethod =
            categoriesWithListingType.includes(values.productCategory) &&
            values.productCategory !== 'restaurantsCafe';

          const submitDisabled =
            invalid ||
            disabled ||
            submitInProgress ||
            !values.productCategory ||
            // categoryDisabeld ||
            !values.pricingType ||
            (showDeliveryMethod && deliveryMethod.length === 0) ||
            (shippingType === deliveryMethodOptions.manualShipping.value &&
              !shippingChargeCustom &&
              !internationalShippingChargeCustom) ||
            (shippingType === deliveryMethodOptions.automaticShipping.value &&
              (automaticShippingOption.length === 0 ||
                !(productHscode && productHscode.desc && productHscode.code)));

          const showDeliveryMethodCondition =
            showDeliveryMethod && isStockFilled(values);
          const additionalItemsDisabled =
            values.listingType === oneItem || values.listingType === 'buffet';

          const handleDeliveryMethod = e => {
            const value = e.target.value;
            const checked = deliveryMethod.includes(value);
            const isPickValue = value === deliveryMethodOptions.pickup.value;
            if (isPickValue) {
              if (!checked) {
                form.change('deliveryMethod', [...deliveryMethod, value]);
              } else {
                form.change(
                  'deliveryMethod',
                  deliveryMethod.filter(v => v !== value)
                );
              }
            } else {
              if (
                !deliveryMethod.includes(deliveryMethodOptions.shipping.value)
              ) {
                form.batch(() => {
                  form.change('deliveryMethod', [
                    ...deliveryMethod,
                    deliveryMethodOptions.shipping.value,
                  ]);
                  form.change('shippingType', value);
                });
              } else {
                form.change('shippingType', value);
              }
            }
          };
          const handleShippingChargeMethod = e => {
            const value = e.target.value;
            const checked = shippingChargeMethod?.includes(value);
            if (!checked) {
              form.change('shippingChargeMethod', [
                ...shippingChargeMethod,
                value,
              ]);
            } else {
              form.change(
                'shippingChargeMethod',
                shippingChargeMethod.filter(v => v !== value)
              );
            }
          };

          const priceRequired = required(
            intl.formatMessage({
              id: 'EditListingPricingForm.priceRequired',
            })
          );
          const priceRequiredForDomesticShipping = requiredShippingPrice(
            intl.formatMessage({
              id: 'EditListingPricingForm.priceRequiredForShipping',
            })
          );
          const priceRequiredForInternationalShipping = requiredInternatioanlShippingPrice(
            intl.formatMessage({
              id:
                'EditListingPricingForm.priceRequiredForInterNationalShipping',
            })
          );
          const minPrice = new Money(
            config.listingMinimumPriceSubUnits,
            config.currency
          );
          const minPriceRequired = moneySubUnitAmountAtLeast(
            intl.formatMessage(
              {
                id: 'EditListingPricingForm.priceTooLow',
              },
              {
                minPrice: formatMoney(intl, minPrice),
              }
            ),
            config.listingMinimumPriceSubUnits
          );
          const priceValidators =
            // config.listingMinimumPriceSubUnits === 0
            // ?
            composeValidators(priceRequired, minPriceRequired);
          const priceValidatorsForShipping = composeValidators(
            priceRequiredForDomesticShipping,
            minPriceRequired
          );
          const priceValidatorsForInterNationalShipping = composeValidators(
            priceRequiredForInternationalShipping,
            minPriceRequired
          );

          // const shippingPriceValidators=composeValidators(priceRequired,)
          //product category
          const productCategoryPlaceholder = 'Product Category*';
          const defaultProductCategory = productCategoryOptions.find(
            e => e.key === values.productCategory
          );
          const defaultCurrencySelector = allowedCurrencyOptions.find(
            e => e.key === values.priceSelector
          );
          const defaultReturnPolicySelector = returnPolicyOptions.find(
            e => e.key === values.returnPolicy
          );
          const [pricingTypeOption, setPricingTypeOption] = useState([]);
          const [selectedPricingType, setSelectedPricingType] = useState([
            allowedPricingTypeOptions.find(
              ({ value }) => value === values?.pricingType
            ),
          ]);

          const [formatedPrice, setFormatedPrice] = useState(null);
          const [formatedPriceForCustom, setFormatedPriceForCustom] = useState(
            null
          );
          const [formatedshippingCharge, setFormatedShippingCharge] = useState(
            null
          );
          const [
            formatedinternationalShippingCharge,
            setFormatedinternationalShippingCharge,
          ] = useState(null);

          const [
            formatedSpecialPriceCharge,
            setFormatedSpecialPriceCharge,
          ] = useState(null);

          const [fieldActive, setFieldActive] = useState({});

          useEffect(() => {
            const initialPrice = initialValues?.price?.amount
              ? initialValues?.price?.amount
              : null;
            const currentPrice = values.price?.amount;
            if (formatedPrice !== initialPrice) {
              if (isNumber(initialPrice)) {
                // form.change(
                //   'priceCustom',
                //   `A$ ${Math.round(initialPrice / 100)}.00`
                // );
                setFormatedPrice(Math.round(initialPrice / 100));
              }
            }

            const initialshippingCharge = initialValues?.shippingCharge?.amount
              ? initialValues?.shippingCharge?.amount
              : null;

            const currentShippingCharge = values.shippingCharge?.amount;
            if (initialshippingCharge !== formatedshippingCharge) {
              if (isNumber(initialshippingCharge)) {
                // form.change(
                //   'shippingChargeCustom',
                //   `A$ ${Math.round(initialshippingCharge / 100)}.00`
                // );
                setFormatedShippingCharge(
                  Math.round(initialshippingCharge / 100)
                );
              }
            }

            const initialInternationalShippingCharge = initialValues
              ?.internationalShippingCharge?.amount
              ? initialValues?.internationalShippingCharge?.amount
              : null;

            const currentInternationalShippingCharge =
              values?.initialInternationalShippingCharge?.amount;
            if (
              formatedinternationalShippingCharge !==
              initialInternationalShippingCharge
            ) {
              if (isNumber(initialInternationalShippingCharge)) {
                // form.change(
                //   'internationalShippingChargeCustom',
                //   `A$ ${Math.round(initialInternationalShippingCharge / 100)}.00`
                // );
                setFormatedinternationalShippingCharge(
                  Math.round(initialInternationalShippingCharge / 100)
                );
              }
            }
            //special price
            const initialSpecialPriceCharge = initialValues?.specialPrice
              ?.amount
              ? initialValues?.specialPrice?.amount
              : null;

            const currentSpecialPriceCharge =
              values?.initialSpecialPriceCharge?.amount;
            if (formatedSpecialPriceCharge !== initialSpecialPriceCharge) {
              if (isNumber(initialSpecialPriceCharge)) {
                setFormatedSpecialPriceCharge(
                  Math.round(initialSpecialPriceCharge / 100)
                );
              }
            }
          }, [initialValues]);

          const getPricingTypeOption = (cat, subCat, listingType) => {
            // console.log(
            //   9876,
            //   cat,
            //   subCat,
              
            // );
            if (cat === 'businessServices') {
              return allowedPricingTypeOptions;
            // } else if (cat === 'carRentals') {
            } else if (cat === 'rental') {
              
              return allowedPricingTypeOptions.filter(
                // ({ value }) => value === 'perDay'
                ({ value }) =>
                  value !== 'perItem' &&
                  value !== 'perPerson' &&
                  value !== 'perKg' &&
                  value !== 'perUnit' &&
                  value !== 'perNight'
              );
            } else if (cat === 'accommodation') {
              return allowedPricingTypeOptions.filter(
                ({ value }) => value === 'perNight'
              );
            } else if (cat === 'restaurantsCafe') {
              if (subCat === 'buffet' || listingType === 'buffet') {
                return allowedPricingTypeOptions.filter(
                  ({ value }) => value === 'perPerson'
                );
              } else if (listingType === 'restaurantsCafe') {
                return allowedPricingTypeOptions.filter(
                  ({ value }) => value === 'perItem'
                );
              } else {
                return [];
              }
            } else if (ALL_ITEMS.includes(cat)) {
              return allowedPricingTypeOptions.filter(
                ({ value }) => value === 'perItem'
              );
            } else {
              return [];
            }
          };

          const subCategoryDisabled = !categoriesWithSubCategories.includes(
            values.productCategory
          );

          const productSubCategories = category => {
            // console.log(4433,category)
            return subCategoryOptions[category] || [];
            // if (category === 'foodDrinks') return foodDrinksSubCategoryOptions;
            // else if (category === 'traditional')
            //   return traditionalSubCategoryOptions;
            // else if (category === 'clothing') return clothingSubCategoryOptions;
            // else if (category === 'jewellery') return jewellerySubCategoryOptions;
            // else if (category === 'beauty') return beautySubCategoryOptions;
            // else if (category === 'books') return booksSubCategoryOptions;
            // else if (category === 'homeDecor') return homeDecorSubCategoryOptions;
            // else if (category === 'furniture') return furnitureSubCategoryOptions;
            // else if (category === 'businessServices')
            //   return businessServicesSubCategoryOptions;
            // else if (category === 'restaurantsCafe')
            //   return restaurantCafeSubCategoryOptions;
            // else return [];
          };

          const quantityRequiredMsg = intl.formatMessage({
            id: 'EditListingDescriptionForm.quantityRequired',
          });

          const productSubCategoryPlaceholder = 'Product Sub Category*';
          const PriceSelectorPlaceHolder = 'Currency';
          const ReturPolicyPlaceholder = 'Choose return policy';

          //listing type
          const listingTypePlaceholder = 'Listing Type*';

          const listingTypeOptions = categoriesWithListingType.includes(
            values.productCategory
          )
            ? values.productCategory === restaurantsCafe
              ? values.productSubCategory === buffet
                ? []
                : listingTypes.filter(type => !itemTypes.includes(type.value))
              : listingTypes.filter(type => !buffetTypes.includes(type.value))
            : [];

          // const listingTypeOptions = categoriesWithListingType.includes(
          //   values.productCategory
          // )
          //   ? values.productCategory === 'restaurantsCafe'
          //     ? !values.productSubCategory === 'buffet'
          //       ? listingTypes.filter(
          //           o => o.value === 'buffet' || o.value === 'restaurantsCafe'
          //         )
          //       : []
          //     : listingTypes.filter(
          //         o => o.value === 'oneItem' || o.value === 'moreItems'
          //       )
          //   : [];

          const defaultListingType = listingTypeOptions.filter(
            e => e.key === initialValues.listingType
          );
          const listingTypeValue = listingTypeOptions.filter(
            e => e.key === values.listingType
          );
          const isListingTypeDisabled =
            listingTypeOptions.length > 0 ? false : true;

          const handleClothingSize = e => {
            const checked = e.target.checked;
            const value = e.target.value;
            if (checked) {
              // setClothingSizes(state => {
              //   return [...state, value];
              // });
              form.change('clothingSize', [...values.clothingSize, value]);
            } else {
              // setClothingSizes(state => {
              //   return state.filter(v => v !== value);
              // });
              form.change('clothingSize', [
                ...values.clothingSize.filter(v => v !== value),
              ]);
            }
          };

          //shoe size change handler
          const handleShoeSizeChange = e => {
            const checked = e.target.checked;
            const value = e.target.value;
            if (checked) {
              form.change('shoeSize', [...values.shoeSize, value]);
            } else {
              form.change('shoeSize', [
                ...values.shoeSize.filter(v => v !== value),
              ]);
            }
          };
          const handleCarTypeChange = e => {
            const checked = e.target.checked;
            const value = e.target.value;
            if (checked) {
              form.change('carType', [...values.carType, value]);
            } else {
              form.change('carType', [
                ...values.carType.filter(v => v !== value),
              ]);
            }
          };
          const handleShippingChargeType = e => {
            const checked = e.target.checked;
            const value = e.target.value;
            if (checked) {
              form.change('shippingChargeType', [
                ...values.shippingChargeType,
                value,
              ]);
            } else {
              form.change('shippingChargeType', [
                ...values.shippingChargeType.filter(v => v !== value),
              ]);
            }
          };

          const handleAvailabilityChange = e => {
            const checked = e.target.checked;
            const value = e.target.value;
            if (checked) {
              form.change('availability', [...values.availability, value]);
            } else {
              form.change('availability', [
                ...values.availability.filter(v => v !== value),
              ]);
            }
          };
          const handleDinningOptions = e => {
            const checked = e.target.checked;
            const value = e.target.value;
            if (checked) {
              // setDinningOptions(state => {
              //   return [...state, value];
              // });
              form.change('dinningType', [...values.dinningType, value]);
            } else {
              // setDinningOptions(state => {
              //   return state.filter(v => v !== value);
              // });
              form.change('dinningType', [
                ...values.dinningType.filter(v => v !== value),
              ]);
            }
          };

          //Accommodation

          const handleAccommodationFeatures = e => {
            const checked = e.target.checked;
            const value = e.target.value;
            if (checked) {
              form.change('accommodationFeatures', [
                ...values.accommodationFeatures,
                value,
              ]);
            } else {
              form.change('accommodationFeatures', [
                ...values.accommodationFeatures.filter(v => v !== value),
              ]);
            }
          };

          // Price change
          const handlePriceChange = e => {
            let value = e.target.value;
            const regex = /^[0-9.\b]+$/;
            if (value.match(regex) || value === '') {
              const dotStartIndex = value.indexOf('.');
              const dotEndIndex = value.lastIndexOf('.');
              if (dotStartIndex !== -1) {
                if (dotStartIndex !== dotEndIndex) {
                  value = value.substring(0, dotStartIndex + 1);
                }
                value = value.substring(0, dotStartIndex + 3);
              }
              const valueFloat = parseFloat(value);

              if (!isNaN(valueFloat)) {
                form.change(
                  `price`,
                  new Money(
                    convertUnitToSubUnit(
                      valueFloat,
                      unitDivisor(config.currencyConfig.currency)
                    ),
                    config.currencyConfig.currency
                  )
                );
              }
              form.change('priceCustom', value);
              setFormatedPrice(value);
            }
          };

          //Currency Price Change
          const handlePriceChangeAsPerCurrency = e => {
            let value = e.target.value;
            const regex = /^[0-9.\b]+$/;
            if (value.match(regex) || value === '') {
              const dotStartIndex = value.indexOf('.');
              const dotEndIndex = value.lastIndexOf('.');
              if (dotStartIndex !== -1) {
                if (dotStartIndex !== dotEndIndex) {
                  value = value.substring(0, dotStartIndex + 1);
                }
                value = value.substring(0, dotStartIndex + 3);
              }
              const valueFloat = parseFloat(value);
              const amountInAUD = convertToAUD(
                valueFloat,
                values?.priceSelector,
                // currencyExchnageCodeCustom
                currencyExchangeCode
              );
              // console.log(5432, amountInAUD);
              if (!isNaN(amountInAUD)) {
                form.change(
                  `price`,
                  new Money(
                    convertUnitToSubUnit(amountInAUD, unitDivisor('AUD')),
                    'AUD'
                  )
                );
              }
              form.change('priceAsPerCurrency', value);
              form.change('priceCustom', `A$ ${amountInAUD}`);
              setFormatedPriceForCustom(value);
            }
          };
          // Special Price change
          const handleSpecialPriceChange = e => {
            let value = e.target.value;
            const regex = /^[0-9.\b]+$/;
            if (value.match(regex) || value === '') {
              const dotStartIndex = value.indexOf('.');
              const dotEndIndex = value.lastIndexOf('.');
              if (dotStartIndex !== -1) {
                if (dotStartIndex !== dotEndIndex) {
                  value = value.substring(0, dotStartIndex + 1);
                }
                value = value.substring(0, dotStartIndex + 3);
              }
              const valueFloat = parseFloat(value);

              const minAllowdValue = isNaN(valueFloat)
                ? value
                : Math.max(1, valueFloat);

              if (!isNaN(valueFloat)) {
                form.change(
                  `specialPrice`,
                  new Money(
                    convertUnitToSubUnit(
                      minAllowdValue,
                      unitDivisor(config.currencyConfig.currency)
                    ),
                    config.currencyConfig.currency
                  )
                );
              }
              form.change('specialPriceCustom', minAllowdValue);
              setFormatedSpecialPriceCharge(minAllowdValue);
            }
          };
          //shipping charge
          const handleShippingCharge = e => {
            let value = e.target.value;
            const regex = /^[0-9.\b]+$/;
            if (value.match(regex) || value === '') {
              const dotStartIndex = value.indexOf('.');
              const dotEndIndex = value.lastIndexOf('.');
              if (dotStartIndex !== -1) {
                if (dotStartIndex !== dotEndIndex) {
                  value = value.substring(0, dotStartIndex + 1);
                }
                value = value.substring(0, dotStartIndex + 3);
              }
              const valueFloat = parseFloat(value);
              if (!isNaN(valueFloat)) {
                form.change(
                  `shippingCharge`,
                  new Money(
                    convertUnitToSubUnit(
                      value,
                      unitDivisor(config.currencyConfig.currency)
                    ),
                    config.currencyConfig.currency
                  )
                );
              }
              form.change('shippingChargeCustom', value);
              setFormatedShippingCharge(value);
            }
          };

          //handle international shipping charges
          const handleIntShippingCharge = e => {
            let value = e.target.value;
            const regex = /^[0-9.\b]+$/;
            if (value.match(regex) || value === '') {
              const dotStartIndex = value.indexOf('.');
              const dotEndIndex = value.lastIndexOf('.');
              if (dotStartIndex !== -1) {
                if (dotStartIndex !== dotEndIndex) {
                  value = value.substring(0, dotStartIndex + 1);
                }
                value = value.substring(0, dotStartIndex + 3);
              }
              const valueFloat = parseFloat(value);
              if (!isNaN(valueFloat)) {
                form.change(
                  `internationalShippingCharge`,
                  new Money(
                    convertUnitToSubUnit(
                      value,
                      unitDivisor(config.currencyConfig.currency)
                    ),
                    config.currencyConfig.currency
                  )
                );
              }
              form.change('internationalShippingChargeCustom', value);
              setFormatedinternationalShippingCharge(value);
            }
          };

          const bedroomCountValue = bathroomBedroomNumbers.filter(
            e => e.key === values.bedroomCount
          );
          const bathroomCountValue = bathroomBedroomNumbers.filter(
            e => e.key === values.bathroomCount
          );
          // console.log(665, values);

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              <FieldTextInput
                id="title"
                name="title"
                // className={css.title}
                className={classNames(css.inputs, {
                  [css.invalidInputs]: touched.title && !!errors.title,
                  [css.fnNonEmptyInputs]: !!values.title || active === 'title',
                })}
                type="text"
                label={'Listing Title*'}
                // placeholder={'Listing Title'}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(
                  required(titleRequiredMessage),
                  maxLength60Message
                )}
                autoFocus
              />
              <FieldTextInput
                id="description"
                name="description"
                className={classNames(css.textInput, {
                  [css.invalidInputs]:
                    touched.description && !!errors.description,
                })}
                type="textarea"
                label={'Description*'}
                placeholder={'Describe your listing here...'}
                validate={composeValidators(
                  required(descriptionRequiredMessage)
                  // maxLength200Message
                )}
              />
              {/* {console.log(554433, values, values?.returnPolicy)} */}
              <div>
                <Field
                  name="returnPolicy"
                  id="returnPolicy"
                  // initialValue={values?.returnPolicy}
                  // validate={composeValidators(
                  //   required('Sub-category required')
                  //   // maxLength200Message
                  // )}
                >
                  {props => (
                    <Select
                      className={classNames(
                        css.categoryReactSelect,
                        css.cateSelectForPrice,
                        {}
                      )}
                      // menuIsOpen={true}
                      isSearchable={false}
                      name={props.input.name}
                      id={props.input.id}
                      options={returnPolicyOptions}
                      // isClearable={true}
                      isDisabled={false}
                      placeholder={null}
                      onChange={e => {
                        if (e === null) {
                          form.change('returnPolicy', '');
                          // setSubCategory(null);
                        } else {
                          form.change('returnPolicy', e.value);
                          form.change('customPolicyDesc', null);
                          // onCurrencyChange(e.value);
                        }
                      }}
                      value={defaultReturnPolicySelector}
                      hideSelectedOptions={true}
                      components={{
                        DropdownIndicator,
                        // MenuForReturnPolicy,
                        // Menu,
                        ValueContainer: getValueContainer(
                          ReturPolicyPlaceholder,
                          ReturPolicyPlaceholder,
                          () => {},
                          css.SCselectedPlaceholder
                        ),
                      }}
                    />
                  )}
                </Field>
              </div>
              {values?.returnPolicy === 'customPolicy' && (
                <FieldTextInput
                  id="customPolicyDesc"
                  name="customPolicyDesc"
                  className={classNames(css.textInput, {
                    [css.invalidInputs]:
                      touched.customPolicyDesc && !!errors.customPolicyDesc,
                  })}
                  type="textarea"
                  // label={'About Custom Return Policy *'}
                  placeholder={'Describe your custom return policy...'}
                  validate={composeValidators(
                    required(descriptionRequiredMessage)
                    // maxLength200Message
                  )}
                />
              )}
              <Select
                className={classNames(
                  css.categoryReactSelect,
                  css.cateSelect,
                  {}
                )}
                // menuIsOpen={true}
                // isSearchable={false}
                name="productCategory"
                id="productCategory"
                options={productCategoryOptions}
                // isClearable={true}
                placeholder={null}
                onChange={e => {
                  // form.change('productCategory', e.value);

                  if (e === null) {
                    form.change('productCategory', '');
                  } else {
                    setCategoryErr(false);
                    form.batch(() => {
                      form.change('productCategory', e.value);
                      form.change('productSubCategory', null);
                      form.change('listingType', null);
                      form.change('requirements', null);
                      form.change('dinningType', []);
                      form.change('accommodationFeatures', []);
                      form.change('bedroomCount', null);
                      form.change('bathroomCount', null);
                      form.change('shoeStockPerSize', null);
                      form.change('clothingStockPerSize', null);
                      form.change('availability', []);
                      form.change('stock', null);
                      form.change('carModelNumber', null);
                      form.change('carType', []);
                      form.change('clothingSize', []);
                      form.change('shoeSize', []);
                    });
                    const pricingType = getPricingTypeOption(e.value);
                    setPricingTypeOption(pricingType);
                    if (pricingType.length === 1) {
                      form.change('pricingType', pricingType[0].key);
                      setSelectedPricingType(pricingType);
                      return;
                    }
                    form.change('pricingType', null);
                    setSelectedPricingType([]);
                  }
                  if (
                    e.value !== 'foodDrinks' ||
                    e.value !== 'traditional' ||
                    e.value !== 'clothing' ||
                    e.value !== 'jewellery' ||
                    e.value !== 'beauty' ||
                    e.value !== 'books' ||
                    e.value !== 'homeDecor' ||
                    e.value !== 'furniture' ||
                    e.value !== 'businessServices' ||
                    e.value !== 'restaurantsCafe'
                  ) {
                    // setSubCategory(null);
                    form.change('productSubCategory', null);
                  }
                  if (e.value !== 'clothing') {
                    // setClothingSizes([]);
                    form.change('clothingSize', []);
                  }
                  if (e.value !== 'shoes') {
                    form.batch(() => {
                      form.change('requirements', null);
                      form.change('shoeSize', []);
                    });
                  }
                  if (e.value !== 'restaurantsCafe') {
                    form.change('dinningType', []);
                  }
                  if (e.value !== 'accommodation') {
                    form.batch(() => {
                      form.change('accommodationFeatures', []);
                      form.change('bedroomCount', null);
                      form.change('bathroomCount', null);
                    });
                  }
                }}
                value={defaultProductCategory}
                // value={values?.productCategory?.label}
                hideSelectedOptions={true}
                components={{
                  DropdownIndicator,
                  // ClearIndicator,
                  Menu,
                  ValueContainer: getValueContainer(
                    productCategoryPlaceholder,
                    productCategoryPlaceholder,
                    // setIsCategoryMenuOpen
                    () => {},
                    css.catSelectedPlaceholder
                  ),
                }}
                onBlur={e => {
                  if (!values?.productCategory) {
                    setCategoryErr(true);
                  }
                }}
              />
              {values.productCategory === 'carRentals' && (
                <React.Fragment>
                  <FieldTextInput
                    id="carModelNumber"
                    name="carModelNumber"
                    // className={css.title}
                    className={classNames(css.inputs, {
                      [css.invalidInputs]:
                        touched.carModelNumber && !!errors.carModelNumber,
                      [css.fnNonEmptyInputs]:
                        !!values.carModelNumber || active === 'carModelNumber',
                    })}
                    type="text"
                    label={'Car Model Number*'}
                    // validate={composeValidators(
                    //   required(carModelRequiredMessage),
                    //   maxLength60Message
                    // )}
                    autoFocus
                  />

                  {/* <FieldCheckboxGroup
                  id="carType"
                  name="carType"
                  // className={classNames(css.inputs, {
                  //   [css.invalidInputs]: touched.title && !!errors.title,
                  //   [css.fnNonEmptyInputs]:
                  //     !!values.title || active === 'carType',
                  // })}
                  className={css.checkboxContainerGroup}
                  label={'Car Type*'}
                  options={carTypeOptions}
                /> */}
                  <div>
                    <div className={css.dmDiv}>
                      <span className={css.divLabel}>Choose the Car Type</span>
                    </div>
                    <div className={css.checkboxContainer}>
                      {CARTYPES.map(type => (
                        <div className={css.checkboxDiv} key={type}>
                          {' '}
                          <div className={css.boxDiv} key={type}>
                            <label
                              htmlFor={type}
                              // onClick={toggleClothingSizeXS}
                              className={css.termLabel}
                            >
                              {values.carType?.includes(type) ? (
                                <BoxChecked className={css.checkBox} />
                              ) : (
                                <BoxUnchecked className={css.checkBox} />
                              )}
                              {type}
                            </label>
                            <input
                              type="checkbox"
                              id={type}
                              checked={values.carType?.includes(type)}
                              // name="clothingSize"
                              className={css.inputBox}
                              value={type}
                              onChange={e => {
                                handleCarTypeChange(e);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              )}

              {categoryErr && !values?.productCategory && (
                <span className={css.errMsg}>Choose a category</span>
              )}
              {!subCategoryDisabled ? (
                <Field
                  name="productSubCategory"
                  id="productSubCategory"
                  validate={composeValidators(
                    required('Sub-category required')
                    // maxLength200Message
                  )}
                >
                  {props => (
                    <Select
                      className={classNames(
                        css.categoryReactSelect,
                        css.cateSelect,
                        {}
                      )}
                      isSearchable={false}
                      name={props.input.name}
                      id={props.input.id}
                      options={productSubCategories(values?.productCategory)}
                      // isClearable={true}
                      isDisabled={subCategoryDisabled}
                      placeholder={null}
                      onChange={e => {
                        if (e === null) {
                          form.change('productSubCategory', '');
                          // setSubCategory(null);
                        } else {
                          form.change('productSubCategory', e.value);
                          form.change('listingType', null);

                          const pricingType = getPricingTypeOption(
                            values.productCategory,
                            e.value
                          );
                          setPricingTypeOption(pricingType);
                          if (pricingType.length === 1) {
                            form.change('pricingType', pricingType[0].key);
                            setSelectedPricingType(pricingType);
                            return;
                          }
                          form.change('pricingType', null);
                          setSelectedPricingType([]);
                          // setSubCategory(e);
                        }
                      }}
                      // value={null}
                      value={
                        getInitialSubcategory(
                          values?.productCategory,
                          values?.productSubCategory
                        ) ?? null
                      }
                      hideSelectedOptions={true}
                      components={{
                        DropdownIndicator,
                        Menu,
                        ValueContainer: getValueContainer(
                          productSubCategoryPlaceholder,
                          productSubCategoryPlaceholder,
                          () => {},
                          css.SCselectedPlaceholder
                        ),
                      }}
                    />
                  )}
                </Field>
              ) : null}

              {!isListingTypeDisabled ? (
                <Field
                  name="listingType"
                  id="listingType"
                  validate={composeValidators(
                    required('listing type required')
                    // maxLength200Message
                  )}
                >
                  {props => (
                    <Select
                      className={classNames(
                        css.categoryReactSelect,
                        css.cateSelect,
                        {}
                      )}
                      isSearchable={false}
                      name={props.input.name}
                      id={props.input.id}
                      options={listingTypeOptions}
                      // isClearable={true}
                      isDisabled={isListingTypeDisabled}
                      placeholder={null}
                      onChange={e => {
                        form.change('listingType', e.value);
                        const pricingType = getPricingTypeOption(
                          values.productCategory,
                          values.productSubCategory,
                          e.value
                        );

                        setPricingTypeOption(pricingType);
                        if (pricingType.length === 1) {
                          form.change('pricingType', pricingType[0].key);
                          setSelectedPricingType(pricingType);
                          return;
                        }
                        form.change('pricingType', null);
                        setSelectedPricingType([]);
                      }}
                      // menuIsOpen={true}
                      // value={defaultListingType}
                      value={listingTypeValue}
                      hideSelectedOptions={true}
                      components={{
                        DropdownIndicator,
                        // ClearIndicator,
                        Menu,
                        ValueContainer: getValueContainer(
                          listingTypePlaceholder,
                          listingTypePlaceholder,
                          // setIsCategoryMenuOpen
                          () => {},
                          css.listingSelectedPlaceholder
                        ),
                      }}
                    />
                  )}
                </Field>
              ) : null}

              {values.productCategory === 'clothing' ? (
                <div className={css.termsText}>
                  <span className={css.divLabel}>Clothing Size*</span>
                  <div className={css.checkboxContainer}>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="sizeXS"
                        // onClick={toggleClothingSizeXS}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('XS') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        XS (2 / 4)
                      </label>
                      <input
                        type="checkbox"
                        id="sizeXS"
                        checked={values.clothingSize?.includes('XS')}
                        // name="clothingSize"
                        className={css.inputBox}
                        value="XS"
                        onChange={e => handleClothingSize(e)}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="sizeS"
                        // onClick={toggleClothingSizeS}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('S') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        S (6 / 8)
                      </label>
                      <input
                        type="checkbox"
                        id="sizeS"
                        className={css.inputBox}
                        name="clothingSize"
                        value="S"
                        onChange={e => handleClothingSize(e)}
                        checked={values.clothingSize?.includes('S')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="sizeM"
                        // onClick={toggleClothingSizeM}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('M') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        M (10 / 12)
                      </label>
                      <input
                        type="checkbox"
                        id="sizeM"
                        className={css.inputBox}
                        name="clothingSize"
                        value="M"
                        onChange={e => handleClothingSize(e)}
                        checked={values.clothingSize?.includes('M')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="sizeL"
                        // onClick={toggleClothingSizeL}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('L') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        L (14 / 16)
                      </label>
                      <input
                        type="checkbox"
                        id="sizeL"
                        className={css.inputBox}
                        name="clothingSize"
                        value="L"
                        onChange={e => handleClothingSize(e)}
                        checked={values.clothingSize?.includes('L')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="sizeXL"
                        // onClick={toggleClothingSizeXL}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('XL') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        XL (18 / 20)
                      </label>
                      <input
                        type="checkbox"
                        id="sizeXL"
                        className={css.inputBox}
                        name="clothingSize"
                        value="XL"
                        onChange={e => handleClothingSize(e)}
                        checked={values.clothingSize?.includes('XL')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="size2XL"
                        // onClick={toggleClothingSize2XL}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('2XL') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        2XL (22 / 24)
                      </label>
                      <input
                        type="checkbox"
                        id="size2XL"
                        className={css.inputBox}
                        name="clothingSize"
                        value="2XL"
                        onChange={e => handleClothingSize(e)}
                        checked={values.clothingSize?.includes('2XL')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="size3XL"
                        // onClick={toggleClothingSize3XL}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('3XL') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        3XL (26 / 28)
                      </label>
                      <input
                        type="checkbox"
                        id="size3XL"
                        className={css.inputBox}
                        name="clothingSize"
                        value="3XL"
                        onChange={e => handleClothingSize(e)}
                        checked={values.clothingSize?.includes('3XL')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="size4XL"
                        // onClick={toggleClothingSize4XL}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('4XL') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        4XL (30 / 32)
                      </label>
                      <input
                        type="checkbox"
                        id="size4XL"
                        className={css.inputBox}
                        name="clothingSize"
                        value="4XL"
                        onChange={e => handleClothingSize(e)}
                        checked={values.clothingSize?.includes('4XL')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="size5XL"
                        // onClick={toggleClothingSize5XL}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('5XL') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        5XL (34 / 36)
                      </label>
                      <input
                        type="checkbox"
                        id="size5XL"
                        className={css.inputBox}
                        name="clothingSize"
                        value="5XL"
                        onChange={e => handleClothingSize(e)}
                        checked={values.clothingSize?.includes('5XL')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label
                        htmlFor="size6XL"
                        // onClick={toggleClothingSize6XL}
                        className={css.termLabel}
                      >
                        {values.clothingSize?.includes('6XL') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        6XL
                      </label>
                      <input
                        type="checkbox"
                        id="size6XL"
                        className={css.inputBox}
                        name="clothingSize"
                        value="6XL"
                        onChange={e => handleClothingSize(e)}
                        checked={values.clothingSize?.includes('6XL')}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {values.productCategory === 'shoes' ? (
                <>
                  {/* <FieldTextInput
                  className={classNames(css.inputs, {
                    [css.invalidInputs]:
                      touched.requirements && !!errors.requirements,
                    [css.fnNonEmptyInputs]:
                      !!values.requirements || active === 'requirements',
                  })}
                  type="text"
                  id="requirements"
                  name="requirements"
                  label={'Requirements*'}
                  validate={composeValidators(
                    required('This field is required')
                  )}
                /> */}
                  <div className={css.termsText}>
                    <span className={css.divLabel}>Shoe Size*</span>
                    <div className={css.checkboxContainer}>
                      {shoesSizeOptions.map(({ key, value, label_UK }) => (
                        <div key={key} className={css.checkboxDiv}>
                          <label
                            htmlFor={key}
                            // onClick={toggleClothingSizeXS}
                            className={css.termLabel}
                          >
                            {values.shoeSize?.includes(value) ? (
                              <BoxChecked className={css.checkBox} />
                            ) : (
                              <BoxUnchecked className={css.checkBox} />
                            )}
                            {label_UK}
                          </label>
                          <input
                            type="checkbox"
                            id={key}
                            checked={values.shoeSize?.includes(value)}
                            // name="clothingSize"
                            className={css.inputBox}
                            value={value}
                            onChange={e => {
                              handleShoeSizeChange(e);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : null}
              {values.productCategory === 'restaurantsCafe' ? (
                <div className={css.termsText}>
                  <span className={css.divLabel}>Dinning Options*</span>
                  <div className={css.checkboxContainer}>
                    <div className={css.checkboxDiv}>
                      <label htmlFor="dineIn" className={css.termLabel}>
                        {values?.dinningType?.includes('dineIn') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        Dine-in
                      </label>

                      <input
                        className={css.inputBox}
                        type="checkbox"
                        id="dineIn"
                        name="dinningType"
                        value="dineIn"
                        onChange={e => handleDinningOptions(e)}
                        checked={values?.dinningType?.includes('dineIn')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label htmlFor="delivery" className={css.termLabel}>
                        {values?.dinningType?.includes('delivery') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        Delivery
                      </label>
                      <input
                        className={css.inputBox}
                        type="checkbox"
                        id="delivery"
                        name="dinningType"
                        value="delivery"
                        onChange={e => handleDinningOptions(e)}
                        checked={values?.dinningType?.includes('delivery')}
                      />
                    </div>
                    <div className={css.checkboxDiv}>
                      <label htmlFor="takeaway" className={css.termLabel}>
                        {values?.dinningType?.includes('takeaway') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        Takeaway
                      </label>
                      <input
                        className={css.inputBox}
                        type="checkbox"
                        id="takeaway"
                        name="takeawayType"
                        value="takeaway"
                        onChange={e => handleDinningOptions(e)}
                        checked={values?.dinningType?.includes('takeaway')}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {values.productCategory === 'accommodation' ? (
                <div>
                  <div className={css.termsText}>
                    <span className={css.divLabel}>
                      Accommodation Features*
                    </span>
                    <div className={css.checkboxContainer}>
                      {accommodationFeaturesOPtions.map(c => {
                        return (
                          <div key={c.value} className={css.checkboxDiv}>
                            <label htmlFor={c.key} className={css.termLabel}>
                              {values?.accommodationFeatures?.includes(
                                c.value
                              ) ? (
                                <BoxChecked className={css.checkBox} />
                              ) : (
                                <BoxUnchecked className={css.checkBox} />
                              )}
                              {c.label}
                            </label>
                            <input
                              className={css.inputBox}
                              type="checkbox"
                              id={c.key}
                              name="accommodationFeatures"
                              value={c.value}
                              onChange={e => handleAccommodationFeatures(e)}
                              checked={values?.accommodationFeatures?.includes(
                                c.value
                              )}
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div>
                      <span className={css.divLabel}>Accessibility*</span>
                      <Select
                        className={classNames(
                          css.categoryReactSelect,
                          css.cateSelect,
                          {}
                        )}
                        isSearchable={false}
                        name="bedroomNumber"
                        id="bedroomNumber"
                        options={bathroomBedroomNumbers}
                        // isClearable={true}
                        // isDisabled={isListingTypeDisabled}
                        placeholder={null}
                        onChange={e => {
                          form.change('bedroomCount', e.value);
                        }}
                        value={bedroomCountValue}
                        hideSelectedOptions={true}
                        components={{
                          DropdownIndicator,
                          // ClearIndicator,
                          Menu,
                          ValueContainer: getValueContainer(
                            'Number of Bedrooms*',
                            'Number of Bedrooms*',
                            // setIsCategoryMenuOpen
                            () => {},

                            css.accomodationFeatureSelectContainer
                          ),
                        }}
                      />
                      <Select
                        className={classNames(
                          css.categoryReactSelect,
                          css.cateSelect,
                          {}
                        )}
                        isSearchable={false}
                        name="bathroomNumber"
                        id="bathroomNumber"
                        options={bathroomBedroomNumbers}
                        // isClearable={true}
                        // isDisabled={isListingTypeDisabled}
                        placeholder={null}
                        onChange={e => {
                          form.change('bathroomCount', e.value);
                        }}
                        value={bathroomCountValue}
                        hideSelectedOptions={true}
                        components={{
                          DropdownIndicator,
                          // ClearIndicator,
                          Menu,
                          ValueContainer: getValueContainer(
                            'Number of Bathrooms*',
                            'Number of Bathrooms*',
                            () => {},
                            // setIsCategoryMenuOpen
                            css.accomodationFeatureSelectContainer
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              <div className={css.pricingDiv}>
                <span className={css.divLabel}>Price</span>
                <p className={css.tip}>
                  {/* Once someone makes an order, you’ll receive this amount minus{' '} */}
                  <span
                    className={css.processingFee}
                    onClick={() => setIsProcessingFeeModalOpen(values)}
                  >
                    Siuhuu commission
                  </span>
                </p>
                <div className={css.flex}>
                  <div className={css.currencySelectorDiv}>
                    <Field
                      name="priceSelector"
                      id="priceSelector"
                      initialValue={values?.priceSelector || 'AUD'}
                      // validate={composeValidators(
                      //   required('Sub-category required')
                      //   // maxLength200Message
                      // )}
                    >
                      {props => (
                        <Select
                          className={classNames(
                            css.categoryReactSelect,
                            css.cateSelectForPrice,
                            {}
                          )}
                          isSearchable={false}
                          name={props.input.name}
                          id={props.input.id}
                          options={allowedCurrencyOptions}
                          // isClearable={true}
                          isDisabled={false}
                          placeholder={null}
                          onChange={e => {
                            if (e === null) {
                              form.change('priceSelector', '');
                              // setSubCategory(null);
                            } else {
                              form.change('priceSelector', e.value);
                              form.change('priceAsPerCurrency', null);
                              // onCurrencyChange(e.value);
                            }
                          }}
                          value={defaultCurrencySelector}
                          hideSelectedOptions={true}
                          components={{
                            DropdownIndicator,
                            Menu,
                            ValueContainer: getValueContainer(
                              PriceSelectorPlaceHolder,
                              PriceSelectorPlaceHolder,
                              () => {},
                              css.SCselectedPlaceholder
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={css.CurrencyFieldAndPRicetypeDiv}>
                    <FieldTextInput
                      id={`priceAsPerCurrency`}
                      name={`priceAsPerCurrency`}
                      type="text"
                      label={`Price (${values?.priceSelector || 'AUD'})*`}
                      className={classNames(css.priceInputsForCurrency, {
                        [css.invalidInputs]:
                          touched.priceAsPerCurrency &&
                          !!errors.priceAsPerCurrency,
                        [css.fnNonEmptyInputs]:
                          !!values.priceAsPerCurrency ||
                          fieldActive.priceAsPerCurrency,
                      })}
                      onChange={handlePriceChangeAsPerCurrency}
                      // onFocus={e => {
                      //   form.change(`priceAsPerCurrency`, formatedPriceForCustom);
                      //   // setFieldActive({
                      //   //   priceCustom: true,
                      //   // });
                      // }}
                      validate={priceValidators}
                      // onBlur={e => {
                      //   const value = e.target.value;
                      //   const valueFloat = parseFloat(value);
                      //   if (!isNaN(valueFloat)) {
                      //     form.change(
                      //       'priceAsPerCurrency',
                      //       values?.priceSelector === 'AUD'
                      //         ? `A$ ${valueFloat}`
                      //         : values?.priceSelector === 'EUR'
                      //         ? `€ ${valueFloat}`
                      //         : values?.priceSelector === 'FJD'
                      //         ? `FJ$ ${valueFloat}`
                      //         : values?.priceSelector === 'NZD'
                      //         ? `NZ$ ${valueFloat}`
                      //         : values?.priceSelector === 'TOP'
                      //         ? `T$ ${valueFloat}`
                      //         : values?.priceSelector === 'USD'
                      //         ? `$ ${valueFloat}`
                      //         : values?.priceSelector === 'WST'
                      //         ? `WS$ ${valueFloat}`
                      //         : values?.priceSelector === 'PGK'
                      //         ? `K ${valueFloat}`
                      //         : `SBD ${valueFloat}`
                      //     );
                      //   }
                      //   // setFieldActive({
                      //   //   priceCustom: false,
                      //   // });
                      //   form.blur('priceAsPerCurrency');
                      //   form.focus('priceAsPerCurrency');
                      // }}
                    />
                    {isArray(pricingTypeOption) &&
                    pricingTypeOption.length <= 1 ? (
                      <Field name="pricingType" id="pricingType">
                        {props => (
                          <div
                            className={classNames(css.priceInputsForCurrency, {
                              [css.invalidInputs]:
                                touched.pricingType && !!errors.pricingType,
                              [css.fnNonEmptyInputs]:
                                isArray(pricingTypeOption) &&
                                pricingTypeOption.length >= 1,
                            })}
                          >
                            <label>Price type*</label>
                            <input
                              name={props.input.name}
                              id={props.input.id}
                              type="text"
                              value={
                                (isArray(pricingTypeOption) &&
                                  pricingTypeOption[0]?.label) ??
                                ''
                              }
                              disabled
                            />
                          </div>
                        )}
                      </Field>
                    ) : (
                      // <FieldTextInput
                      //   id="pricingType"
                      //   name="pricingType"
                      //   className={classNames(css.priceInputs, {
                      //     [css.invalidInputs]:
                      //       touched.pricingType && !!errors.pricingType,
                      //     [css.fnNonEmptyInputs]:
                      //       isArray(pricingTypeOption) &&
                      //       pricingTypeOption.length >= 1,
                      //     // || active === 'pricingType',
                      //   })}
                      //   type="text"
                      //   label={'Price type*'}
                      //   // placeholder={'Listing Title'}
                      //   disabled={true}
                      // />
                      <Select
                        className={classNames(
                          css.categoryReactSelect,
                          css.priceReactSelect,
                          css.cateSelect,
                          {}
                        )}
                        isSearchable={false}
                        name="pricingType"
                        id="pricingType"
                        options={pricingTypeOption}
                        // isClearable={true}
                        // isDisabled={isListingTypeDisabled}
                        placeholder={null}
                        onChange={e => {
                          form.change('pricingType', e.value);
                          setSelectedPricingType(e);
                        }}
                        value={selectedPricingType}
                        hideSelectedOptions={true}
                        components={{
                          DropdownIndicator,
                          // ClearIndicator,
                          Menu,
                          ValueContainer: getValueContainer(
                            'Price type*',
                            'Price type*',
                            () => {},
                            // setIsCategoryMenuOpen
                            css.listingSelectedPlaceholder
                          ),
                        }}
                        // isDisabled={
                        //   isArray(pricingTypeOption) && pricingTypeOption.length <= 1
                        // }
                      />
                    )}
                  </div>
                </div>
                <div className={css.priceFieldDiv}>
                  <FieldTextInput
                    id={`priceCustom`}
                    name={`priceCustom`}
                    type="text"
                    label={` Product Price in AUD*`}
                    className={classNames(css.priceInputsForShow, {
                      [css.invalidInputs]:
                        touched.priceCustom && !!errors.priceCustom,
                      [css.fnNonEmptyInputs]:
                        !!values.priceCustom || fieldActive.priceCustom,
                    })}
                    disabled={true}
                    // onChange={handlePriceChange}
                    // onFocus={e => {
                    //   form.change(`priceCustom`, formatedPrice);
                    //   setFieldActive({
                    //     priceCustom: true,
                    //   });
                    // }}
                    // validate={priceValidators}
                    onBlur={e => {
                      const value = e.target.value;
                      const valueFloat = parseFloat(value);
                      if (!isNaN(valueFloat)) {
                        form.change('priceCustom', `A$ ${valueFloat}`);
                      }
                      setFieldActive({
                        priceCustom: false,
                      });
                      form.blur('priceCustom');
                      form.focus('priceCustom');
                    }}
                  />
                </div>
                {/* special price field */}
                <div className={css.splprWrapper}>
                  <FieldCheckbox
                    name="specialPriceReq"
                    id="specialPriceReq"
                    value="yes"
                    label={
                      <span className={css.checkboxLabel}>
                        {values.specialPriceReq &&
                        values.specialPriceReq.includes('yes') ? (
                          <BoxChecked className={css.checkBox} />
                        ) : (
                          <BoxUnchecked className={css.checkBox} />
                        )}
                        <span>Special price</span>
                      </span>
                    }
                    className={css.specialPrReq}
                  />
                  {values.specialPriceReq &&
                    values.specialPriceReq.includes('yes') && (
                      <FieldTextInput
                        id={`specialPriceCustom`}
                        name={`specialPriceCustom`}
                        type="text"
                        label={`Special Price in AUD`}
                        className={classNames(css.priceInputs, {
                          [css.invalidInputs]:
                            touched.specialPrice && !!errors.specialPrice,
                          [css.fnNonEmptyInputs]:
                            !!values.specialPrice || fieldActive.specialPrice,
                        })}
                        onChange={handleSpecialPriceChange}
                        onFocus={e => {
                          form.change(
                            `specialPriceCustom`,
                            formatedSpecialPriceCharge
                          );
                          setFieldActive({
                            specialPriceCustom: true,
                          });
                        }}
                        onBlur={e => {
                          const value = e.target.value;
                          const valueFloat = parseFloat(value);
                          if (!isNaN(valueFloat)) {
                            form.change(
                              'specialPriceCustom',
                              `A$ ${valueFloat}`
                            );
                          }

                          setFieldActive({
                            specialPriceCustom: false,
                          });
                        }}
                      />
                    )}
                </div>

                {/* custom quote allow field */}
                {isCustomQuote ? (
                  <div className={css.quoteContainer}>
                    {customerEmail && (
                      <>
                        <div className={css.emailLabel}>Custom Quote For: </div>
                        <div className={css.email}>{customerEmail}</div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className={css.splprWrapper}>
                    <FieldCheckbox
                      name="allowCustomQuote"
                      id="allowCustomQuote"
                      value="yes"
                      label={
                        <span className={css.checkboxLabel}>
                          {values.allowCustomQuote &&
                          values.allowCustomQuote.includes('yes') ? (
                            <BoxChecked className={css.checkBox} />
                          ) : (
                            <BoxUnchecked className={css.checkBox} />
                          )}
                          <span>Allow custom quote</span>
                        </span>
                      }
                      className={css.specialPrReq}
                    />
                  </div>
                )}
              </div>

              {/* <FieldCurrencyInput
              id="price"
              name="price"
              // className={css.priceInput}
              className={classNames(css.inputs, {
                [css.invalidInputs]: touched.price && !!errors.price,
                [css.fnNonEmptyInputs]: !!values.price || active === 'price',
              })}
              label={` Product Price in AUD`}
              placeholder={' '}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            /> */}

              {values.productCategory === 'clothing' &&
                values.clothingSize?.length > 0 && (
                  <div className={css.termsText}>
                    <span className={css.divLabel}>Clothing Stock</span>
                    {values.clothingSize?.map(size => (
                      <FieldTextInput
                        id={`clothingStockPerSize.${size}`}
                        name={`clothingStockPerSize.${size}`}
                        key={`clothingStockPerSize.${size}`}
                        className={classNames(css.inputs, {
                          [css.invalidInputs]:
                            touched[`clothingStockPerSize.${size}`] &&
                            !!errors[`clothingStockPerSize.${size}`],
                          [css.fnNonEmptyInputs]:
                            (!!values &&
                              values.clothingStockPerSize &&
                              values.clothingStockPerSize[size] >= 0) ||
                            active === `clothingStockPerSize.${size}`,
                        })}
                        type="text"
                        label={size}
                        onChange={e => {
                          const value = e.target.value;
                          const regex = /^[0-9\b]+$/;
                          if (value.match(regex) || value === '')
                            form.change(`clothingStockPerSize.${size}`, value);
                        }}
                        validate={composeValidators(
                          numberAtLeast(quantityRequiredMsg, 1)
                        )}
                      />
                    ))}
                  </div>
                )}
              {values.productCategory === 'shoes' &&
                values.shoeSize?.length > 0 && (
                  <div className={css.termsText}>
                    <span className={css.divLabel}>Shoe Stock</span>
                    {values.shoeSize?.map(size => (
                      <FieldTextInput
                        id={`shoeStockPerSize.${size}`}
                        key={`shoeStockPerSize.${size}`}
                        name={`shoeStockPerSize.${size}`}
                        className={classNames(css.inputs, {
                          [css.invalidInputs]:
                            touched[`shoeStockPerSize.${size}`] &&
                            !!errors[`shoeStockPerSize.${size}`],
                          [css.fnNonEmptyInputs]:
                            (!!values &&
                              values.shoeStockPerSize &&
                              values.shoeStockPerSize[size] >= 0) ||
                            active === `shoeStockPerSize.${size}`,
                        })}
                        type="text"
                        label={`Size - ${
                          shoeSizes.find(({ key }) => key === size).label_UK
                        }`}
                        onChange={e => {
                          const value = e.target.value;
                          const regex = /^[0-9\b]+$/;
                          if (value.match(regex) || value === '')
                            form.change(`shoeStockPerSize.${size}`, value);
                        }}
                        validate={composeValidators(
                          numberAtLeast(quantityRequiredMsg, 1)
                        )}
                      />
                    ))}
                  </div>
                )}
              {values &&
                values.productCategory &&
                values.productCategory !== 'clothing' &&
                values.productCategory !== 'shoes' &&
                !AUTO_STOCK.includes(values.productCategory) && (
                  <div className={css.termsText}>
                    <span className={css.divLabel}>Stock</span>
                    <FieldTextInput
                      id="stock"
                      name="stock"
                      // className={css.title}
                      className={classNames(css.inputs, {
                        [css.invalidInputs]: touched.stock && !!errors.stock,
                        [css.fnNonEmptyInputs]:
                          values.stock >= 0 || active === 'stock',
                      })}
                      type="text"
                      label={'Quantity*'}
                      onChange={e => {
                        const value = e.target.value;
                        const regex = /^[0-9\b]+$/;
                        if (value.match(regex) || value === '')
                          form.change('stock', value);
                      }}
                      validate={composeValidators(
                        numberAtLeast(quantityRequiredMsg, 1)
                      )}
                      // autoFocus
                    />
                  </div>
                )}
              {(values.productCategory === 'businessServices' ||
                values.productCategory === 'restaurantsCafe' ||
                values.productCategory === 'accommodation') && (
                <div>
                  <div className={css.dmDiv}>
                    {/* <span className={css.divTitle}>Delivery Method</span> */}
                    <span className={css.divLabel}>
                      Choose the availability
                    </span>
                  </div>
                  <div className={css.checkboxContainer}>
                    {WEEKDAYS.map(days => (
                      <div className={css.checkboxDiv} key={days}>
                        {' '}
                        <div className={css.boxDiv} key={days}>
                          <label
                            htmlFor={days}
                            // onClick={toggleClothingSizeXS}
                            className={css.termLabel}
                          >
                            {values.availability?.includes(days) ? (
                              <BoxChecked className={css.checkBox} />
                            ) : (
                              <BoxUnchecked className={css.checkBox} />
                            )}
                            {days}
                          </label>
                          <input
                            type="checkbox"
                            id={days}
                            checked={values.availability?.includes(days)}
                            // name="clothingSize"
                            className={css.inputBox}
                            value={days}
                            onChange={e => {
                              handleAvailabilityChange(e);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {showDeliveryMethodCondition ? (
                <div>
                  <div className={css.dmDiv}>
                    {/* <span className={css.divTitle}>Delivery Method</span> */}
                    <span className={css.divLabel}>
                      Choose the delivery options you provide to your customer.
                    </span>
                  </div>
                  <div className={css.boxDiv}>
                    <React.Fragment>
                      <label
                        htmlFor={deliveryMethodOptions.automaticShipping.value}
                        className={css.termLabel}
                      >
                        {deliveryMethod.includes(
                          deliveryMethodOptions.shipping.value
                        ) &&
                        shippingType ===
                          deliveryMethodOptions.automaticShipping.value ? (
                          <RadioChecked className={css.checkBox} />
                        ) : (
                          <RadioUnchecked className={css.checkBox} />
                        )}
                        {deliveryMethodOptions.automaticShipping.label}
                      </label>
                      <input
                        type="radio"
                        id={deliveryMethodOptions.automaticShipping.value}
                        className={css.inputBox}
                        name="shippingType"
                        value={deliveryMethodOptions.automaticShipping.value}
                        onChange={handleDeliveryMethod}
                        checked={deliveryMethod.includes(
                          deliveryMethodOptions.automaticShipping.value
                        )}
                      />
                    </React.Fragment>

                    <label
                      htmlFor={deliveryMethodOptions.manualShipping.value}
                      className={css.termLabel}
                    >
                      {deliveryMethod.includes(
                        deliveryMethodOptions.shipping.value
                      ) &&
                      shippingType !==
                        deliveryMethodOptions.automaticShipping.value ? (
                        <RadioChecked className={css.checkBox} />
                      ) : (
                        <RadioUnchecked className={css.checkBox} />
                      )}
                      {deliveryMethodOptions.manualShipping.label}
                    </label>
                    <input
                      type="radio"
                      id={deliveryMethodOptions.manualShipping.value}
                      className={css.inputBox}
                      name="shippingType"
                      value={deliveryMethodOptions.manualShipping.value}
                      onChange={handleDeliveryMethod}
                      checked={deliveryMethod.includes(
                        deliveryMethodOptions.manualShipping.value
                      )}
                    />
                    <label
                      htmlFor={deliveryMethodOptions.pickup.value}
                      className={css.termLabel}
                    >
                      {deliveryMethod.includes(
                        deliveryMethodOptions.pickup.value
                      ) ? (
                        <BoxChecked className={css.checkBox} />
                      ) : (
                        <BoxUnchecked className={css.checkBox} />
                      )}
                      {deliveryMethodOptions.pickup.label}
                    </label>
                    <input
                      type="checkbox"
                      id={deliveryMethodOptions.pickup.value}
                      name="deliveryMethod"
                      className={css.inputBox}
                      value={deliveryMethodOptions.pickup.value}
                      onChange={handleDeliveryMethod}
                      checked={deliveryMethod.includes(
                        deliveryMethodOptions.pickup.value
                      )}
                    />
                  </div>
                  <div className={css.shippingInfoBtn}>
                    <InlineTextButton
                      onClick={e => {
                        e.preventDefault();
                        setShowCourierPickupInfoModal(values);
                      }}
                      type="button"
                    >
                      <FormattedMessage id="CartPage.whatIsShipping" />
                    </InlineTextButton>
                  </div>
                  {deliveryMethod.includes(
                    deliveryMethodOptions.shipping.value
                  ) ? (
                    <div className={css.chargeDiv}>
                      {shippingType ===
                      deliveryMethodOptions.manualShipping.value ? (
                        <React.Fragment>
                          {/* <FieldCurrencyInput
                      id="shippingCharge"
                      name="shippingCharge"
                      className={classNames(css.inputs, {
                        [css.invalidInputs]:
                          touched.shippingCharge && !!errors.shippingCharge,
                        [css.fnNonEmptyInputs]:
                          !!values.shippingCharge ||
                          active === 'shippingCharge',
                      })}
                      label={'Domestic Shipping Charge in AUD'}
                      placeholder={' '}
                      currencyConfig={config.currencyConfig}
                      validate={composeValidators(
                        required('You need to add a shipping charge.')
                      )}
                    /> */}
                          {/*<FieldTextInput
                          id={`shippingChargeCustom`}
                          name={`shippingChargeCustom`}
                          type="text"
                          label={'Domestic Shipping Charge in AUD'}
                          className={classNames(css.inputs, {
                            [css.invalidInputs]:
                              touched.shippingChargeCustom &&
                              !!errors.shippingChargeCustom,
                            [css.fnNonEmptyInputs]:
                              !!values.shippingChargeCustom ||
                              fieldActive.shippingChargeCustom,
                          })}
                          onChange={handleShippingCharge}
                          onFocus={e => {
                            form.change(
                              `shippingChargeCustom`,
                              formatedshippingCharge
                            );
                            setFieldActive({
                              shippingChargeCustom: true,
                            });
                          }}
                          onBlur={e => {
                            const value = e.target.value;
                            const valueFloat = parseFloat(value);
                            if (!isNaN(valueFloat)) {
                              form.change(
                                'shippingChargeCustom',
                                `A$ ${valueFloat}`
                              );
                            }
                            setFieldActive({
                              shippingChargeCustom: false,
                            });
                          }}
                        />*/}
                          {/* <FieldCurrencyInput
                      id="internationalShippingCharge"
                      name="internationalShippingCharge"
                      className={classNames(css.inputs, {
                        [css.invalidInputs]:
                          touched.internationalShippingCharge &&
                          !!errors.internationalShippingCharge,
                        [css.fnNonEmptyInputs]:
                          !!values.internationalShippingCharge ||
                          active === 'internationalShippingCharge',
                      })}
                      // autoFocus={autoFocus}
                      label={'International Shipping Charge in AUD'}
                      placeholder={' '}
                      currencyConfig={config.currencyConfig}
                    /> */}

                          {/*<FieldTextInput
                          id={`internationalShippingChargeCustom`}
                          name={`internationalShippingChargeCustom`}
                          type="text"
                          label={'International Shipping Charge in AUD'}
                          className={classNames(css.inputs, {
                            [css.invalidInputs]:
                              touched.internationalShippingChargeCustom &&
                              !!errors.internationalShippingChargeCustom,
                            [css.fnNonEmptyInputs]:
                              !!values.internationalShippingChargeCustom ||
                              fieldActive.internationalShippingChargeCustom,
                          })}
                          onChange={handleIntShippingCharge}
                          onFocus={e => {
                            form.change(
                              `internationalShippingChargeCustom`,
                              formatedinternationalShippingCharge
                            );
                            setFieldActive({
                              internationalShippingChargeCustom: true,
                            });
                          }}
                          onBlur={e => {
                            const value = e.target.value;
                            const valueFloat = parseFloat(value);
                            if (!isNaN(valueFloat)) {
                              form.change(
                                'internationalShippingChargeCustom',
                                `A$ ${valueFloat}`
                              );
                            }
                            setFieldActive({
                              internationalShippingChargeCustom: false,
                            });
                          }}
                        />*/}
                          {values.deliveryMethod?.length > 0 &&
                            values.deliveryMethod?.includes('shipping') && (
                              <>
                                <div className={css.dmDiv}>
                                  <span className={css.divLabel}>
                                    Choose Shipping type options.
                                  </span>
                                </div>
                                <div className={css.boxDiv}>
                                  <label
                                    htmlFor={
                                      shippingChargeOptions.domestic.value
                                    }
                                    className={css.termLabel}
                                  >
                                    {shippingChargeMethod?.includes(
                                      shippingChargeOptions.domestic.value
                                    ) ? (
                                      <BoxChecked className={css.checkBox} />
                                    ) : (
                                      <BoxUnchecked className={css.checkBox} />
                                    )}
                                    {shippingChargeOptions.domestic.label}
                                  </label>
                                  <input
                                    type="checkbox"
                                    id={shippingChargeOptions.domestic.value}
                                    className={css.inputBox}
                                    name="shippingChargeMethod"
                                    value={shippingChargeOptions.domestic.value}
                                    onChange={handleShippingChargeMethod}
                                    checked={shippingChargeMethod?.includes(
                                      shippingChargeOptions.domestic.value
                                    )}
                                  />
                                  <label
                                    htmlFor={
                                      shippingChargeOptions.international.value
                                    }
                                    className={css.termLabel}
                                  >
                                    {shippingChargeMethod?.includes(
                                      shippingChargeOptions.international.value
                                    ) ? (
                                      <BoxChecked className={css.checkBox} />
                                    ) : (
                                      <BoxUnchecked className={css.checkBox} />
                                    )}
                                    {shippingChargeOptions.international.label}
                                  </label>
                                  <input
                                    type="checkbox"
                                    id={
                                      shippingChargeOptions.international.value
                                    }
                                    name="shippingChargeMethod"
                                    className={css.inputBox}
                                    value={
                                      shippingChargeOptions.international.value
                                    }
                                    onChange={handleShippingChargeMethod}
                                    checked={shippingChargeMethod?.includes(
                                      shippingChargeOptions.international.value
                                    )}
                                  />
                                </div>
                              </>
                            )}
                          {deliveryMethod.includes('shipping') &&
                          !shippingChargeMethod.includes(
                            shippingChargeOptions.international.value
                          ) &&
                          shippingChargeMethod.includes(
                            shippingChargeOptions.domestic.value
                          ) ? (
                            <div className={css.chargeDiv}>
                              <span className={css.divLabel}>
                                Shipping Charge
                              </span>

                              <FieldTextInput
                                id={`shippingChargeCustom`}
                                name={`shippingChargeCustom`}
                                type="text"
                                label={
                                  'Domestic Shipping Charge in AUD (charge should be greater than 0)'
                                }
                                className={classNames(css.inputs, {
                                  [css.invalidInputs]:
                                    touched.shippingChargeCustom &&
                                    !!errors.shippingChargeCustom,
                                  [css.fnNonEmptyInputs]:
                                    !!values.shippingChargeCustom ||
                                    fieldActive.shippingChargeCustom,
                                })}
                                onChange={handleShippingCharge}
                                onFocus={e => {
                                  form.change(
                                    `shippingChargeCustom`,
                                    formatedshippingCharge
                                  );
                                  setFieldActive({
                                    shippingChargeCustom: true,
                                  });
                                }}
                                onBlur={e => {
                                  const value = e.target.value;
                                  const valueFloat = parseFloat(value);
                                  if (!isNaN(valueFloat)) {
                                    form.change(
                                      'shippingChargeCustom',
                                      `A$ ${valueFloat}`
                                    );
                                  }
                                  setFieldActive({
                                    shippingChargeCustom: false,
                                  });
                                  form.blur('shippingChargeCustom');
                                  form.focus('shippingChargeCustom');
                                }}
                                validate={priceValidatorsForShipping}
                              />
                            </div>
                          ) : deliveryMethod.includes('shipping') &&
                            !shippingChargeMethod.includes(
                              shippingChargeOptions.domestic.value
                            ) &&
                            shippingChargeMethod.includes(
                              shippingChargeOptions.international.value
                            ) ? (
                            <div className={css.chargeDiv}>
                              <span className={css.divLabel}>
                                Shipping Charge
                              </span>

                              <FieldTextInput
                                id={`internationalShippingChargeCustom`}
                                name={`internationalShippingChargeCustom`}
                                type="text"
                                label={
                                  'International Shipping Charge in AUD (charge should be greater than 0)'
                                }
                                className={classNames(css.inputs, {
                                  [css.invalidInputs]:
                                    touched.internationalShippingChargeCustom &&
                                    !!errors.internationalShippingChargeCustom,
                                  [css.fnNonEmptyInputs]:
                                    !!values.internationalShippingChargeCustom ||
                                    fieldActive.internationalShippingChargeCustom,
                                })}
                                onChange={handleIntShippingCharge}
                                onFocus={e => {
                                  form.change(
                                    `internationalShippingChargeCustom`,
                                    formatedinternationalShippingCharge
                                  );
                                  setFieldActive({
                                    internationalShippingChargeCustom: true,
                                  });
                                }}
                                onBlur={e => {
                                  const value = e.target.value;
                                  const valueFloat = parseFloat(value);
                                  if (!isNaN(valueFloat)) {
                                    form.change(
                                      'internationalShippingChargeCustom',
                                      `A$ ${valueFloat}`
                                    );
                                  }
                                  setFieldActive({
                                    internationalShippingChargeCustom: false,
                                  });
                                  form.blur(
                                    'internationalShippingChargeCustom'
                                  );
                                  form.focus(
                                    'internationalShippingChargeCustom'
                                  );
                                }}
                                validate={
                                  priceValidatorsForInterNationalShipping
                                }
                              />
                            </div>
                          ) : deliveryMethod.includes('shipping') &&
                            shippingChargeMethod.includes(
                              shippingChargeOptions.domestic.value
                            ) &&
                            shippingChargeMethod.includes(
                              shippingChargeOptions.international.value
                            ) ? (
                            <div className={css.chargeDiv}>
                              <span className={css.divLabel}>
                                Shipping Charge
                              </span>
                              <FieldTextInput
                                id={`shippingChargeCustom`}
                                name={`shippingChargeCustom`}
                                type="text"
                                label={
                                  'Domestic Shipping Charge in AUD (charge should be greater than 0)'
                                }
                                className={classNames(css.inputs, {
                                  [css.invalidInputs]:
                                    touched.shippingChargeCustom &&
                                    !!errors.shippingChargeCustom,
                                  [css.fnNonEmptyInputs]:
                                    !!values.shippingChargeCustom ||
                                    fieldActive.shippingChargeCustom,
                                })}
                                onChange={handleShippingCharge}
                                onFocus={e => {
                                  form.change(
                                    `shippingChargeCustom`,
                                    formatedshippingCharge
                                  );
                                  setFieldActive({
                                    shippingChargeCustom: true,
                                  });
                                }}
                                onBlur={e => {
                                  const value = e.target.value;
                                  const valueFloat = parseFloat(value);
                                  if (!isNaN(valueFloat)) {
                                    form.change(
                                      'shippingChargeCustom',
                                      `A$ ${valueFloat}`
                                    );
                                  }
                                  setFieldActive({
                                    shippingChargeCustom: false,
                                  });
                                  form.blur('shippingChargeCustom');
                                  form.focus('shippingChargeCustom');
                                }}
                                validate={priceValidatorsForShipping}
                              />
                              <FieldTextInput
                                id={`internationalShippingChargeCustom`}
                                name={`internationalShippingChargeCustom`}
                                type="text"
                                label={
                                  'International Shipping Charge in AUD (charge should be greater than 0)'
                                }
                                className={classNames(css.inputs, {
                                  [css.invalidInputs]:
                                    touched.internationalShippingChargeCustom &&
                                    !!errors.internationalShippingChargeCustom,
                                  [css.fnNonEmptyInputs]:
                                    !!values.internationalShippingChargeCustom ||
                                    fieldActive.internationalShippingChargeCustom,
                                })}
                                onChange={handleIntShippingCharge}
                                onFocus={e => {
                                  form.change(
                                    `internationalShippingChargeCustom`,
                                    formatedinternationalShippingCharge
                                  );
                                  setFieldActive({
                                    internationalShippingChargeCustom: true,
                                  });
                                }}
                                onBlur={e => {
                                  const value = e.target.value;
                                  const valueFloat = parseFloat(value);
                                  if (!isNaN(valueFloat)) {
                                    form.change(
                                      'internationalShippingChargeCustom',
                                      `A$ ${valueFloat}`
                                    );
                                  }
                                  setFieldActive({
                                    internationalShippingChargeCustom: false,
                                  });
                                  form.blur(
                                    'internationalShippingChargeCustom'
                                  );
                                  form.focus(
                                    'internationalShippingChargeCustom'
                                  );
                                }}
                                validate={
                                  priceValidatorsForInterNationalShipping
                                }
                              />
                            </div>
                          ) : null}
                        </React.Fragment>
                      ) : null}
                      <ParcelDetailsComponent
                        values={values}
                        intl={intl}
                        form={form}
                      />
                      {/* {!additionalItemsDisabled && (
                      <FieldCurrencyInput
                        id="additionalItems"
                        name="additionalItems"
                        className={classNames(
                          css.inputs,
                          {
                            [css.invalidInputs]:
                              touched.additionalItems &&
                              !!errors.additionalItems,
                            [css.fnNonEmptyInputs]:
                              !!values.additionalItems ||
                              active === 'additionalItems',
                          },
                          additionalItemsDisabled && css.disabled
                        )}
                        // autoFocus={autoFocus}
                        label={'Additional Items*'}
                        placeholder={' '}
                        // placeholder={intl.formatMessage({
                        //   id: 'EditListingPricingForm.priceInputPlaceholder',
                        // })}
                        currencyConfig={config.currencyConfig}
                        disabled={additionalItemsDisabled}
                        validate={
                          !additionalItemsDisabled &&
                          composeValidators(
                            required('You need to add additional item price.')
                          )
                        }
                      />
                    )} */}
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className={css.lowerDiv}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {/* {saveActionMsg} */}
                  {!props.isPublished ? 'Save & Next' : 'Save Changes'}
                </Button>
                <button
                  className={css.cancelButton}
                  type="button"
                  onClick={() => {
                    // form.reset;
                    history.push(
                      createResourceLocatorString(
                        'LandingPage',
                        routeConfiguration(),
                        {},
                        {}
                      )
                    );
                  }}
                >
                  Cancel
                </button>
              </div>
            </Form>
          );
        }}
      />

      <Modal
        id="processingFeeModal"
        isOpen={showCourierPickupInfoModal}
        onClose={() => setShowCourierPickupInfoModal(false)}
        onManageDisableScrolling={props.onManageDisableScrolling}
        containerClassName={css.modalContainer}
        usePortal
      >
        <div className={css.info}>
          <span className={css.infoChild}>
            <span>
              <IoMdInformationCircle />{' '}
              {deliveryMethodOptions.automaticShipping.label}:{'  '}
            </span>
            <FormattedMessage id="CartPage.automaticShippingInfo" />
          </span>
          <span className={css.infoChild}>
            <span>
              <IoMdInformationCircle />{' '}
              {deliveryMethodOptions.manualShipping.label}:{'  '}
            </span>
            <FormattedMessage id="CartPage.manualShippingInfo" />
          </span>
        </div>
      </Modal>

      <Modal
        id="processingFeeModal"
        isOpen={isprocessingFeeModalOpen}
        onClose={() => setIsProcessingFeeModalOpen(false)}
        onManageDisableScrolling={props.onManageDisableScrolling}
        containerClassName={css.modalContainer}
        usePortal
      >
        <h2>Commission and Payment processing fee</h2>
        <p>
          {/* Payments in Siuhuu are processed by the payment gateway listed
                  below. Siuhuu will charge a commission depending on the
                  payment gateway. The payment gateway may also charge a fee for
                  each payment, as described below. */}
        </p>
        <span>
          In Siuhuu, commission refers to the fee we charge sellers for using
          our platform to reach buyers. This fee is a percentage of each sale
          made through the marketplace. It helps us maintain and improve the
          platform, ensuring a great experience for both sellers and buyers.
        </span>

        <h3>Stripe</h3>
        <p>
          For Stripe payments, Siuhuu charges a commission of 5%.
          {/* The minimum commission per transaction is $0. Stripe's fees are
                  included in this commission. */}
        </p>

        <h3>PayPal</h3>
        <p>
          For PayPal payments, Siuhuu charges a commission of 5%.
          {/* The
                  minimum transaction fee per transaction is $0. PayPal's fees
                  are not included in this commission, so in addition to Siuhuu
                  commission, you will also pay PayPal's fees for each. PayPal's
                  fee is between 2% and 5% of the total sales price, depending
                  on your monthly sales volume and the country of residence of
                  the buyer. In general, domestic purchases have lower fees than
                  international ones, and higher monthly sales give you a
                  discount on fees. */}
          <br />
          {/* You can see the exact fees by logging in to your PayPal
                  account and going{' '}
                  <ExternalLink href="https://www.paypal.com/au/webapps/mpp/merchant-fees">
                    here
                  </ExternalLink>
                  . After each purchase you will get a receipt displaying the
                  exact fee. */}
        </p>
        <h3>Pacific Islander</h3>
        <p>
          Total payout amount reflects a 5% deduction for platform fees. Any
          additional transfer fees for payouts to the Pacific Islands are not
          included and may be charged separately by the payment provider.
        </p>
      </Modal>
    </React.Fragment>
  );
};

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(
  withRouter,
  injectIntl
)(EditListingDescriptionFormComponent);
