import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { compose } from 'redux';
import {
  fetchExchangeCode,
  updateSelectedCurrency,
} from '../../ducks/currencyExchangeCode.duck';
import { allowedCurrencyOptions } from '../../marketplace-custom-config';
import css from './CurrencyExchangeDropdown.module.css';

function CurrencyExchangeDropdownComponent(props) {
  const {
    onCurrencyChange,
    onUpdateSelectedCurrency,
    selectedCurrencyExchangeCode,
    selectedCurrency,
    currencyExchangeCode,
  } = props;
  return (
    <div className={css.mainContainer}>
      <Select
        id="priceType"
        name="priceType"
        className={`${css.priceType} priceType`}
        value={allowedCurrencyOptions.find(
          ({ key }) => key === selectedCurrency
        )}
        // menuIsOpen={true}
        options={allowedCurrencyOptions}
        onChange={e => {
          // if (typeof window !== 'undefined') {
          //   window.localStorage.setItem('selectedCurrency', e.key);
          //   console.log('selected currency');
          // }
          onCurrencyChange(e.key);
        }}
        // components={{ Option }}
        // className={css.formMargins}
      />
    </div>
  );
}
const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    selectedCurrencyExchangeCode,
    selectedCurrency,
    currencyExchangeCode,
  } = state.currencyExchangeCode;

  return {
    currentUser,
    selectedCurrencyExchangeCode,
    selectedCurrency,
    currencyExchangeCode,
  };
};

const mapDispatchToProps = dispatch => ({
  onCurrencyChange: data => dispatch(fetchExchangeCode(data)),
  onUpdateSelectedCurrency: data => dispatch(updateSelectedCurrency(data)),
});

const CurrencyExchangeDropdown = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(CurrencyExchangeDropdownComponent);

export default CurrencyExchangeDropdown;
