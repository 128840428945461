import axios from 'axios';
import { apiBaseUrl } from '../util/api';

export const FETCH_EXCHANGE_CODE_REQUEST =
  'app/user/FETCH_EXCHANGE_CODE_REQUEST';
export const FETCH_EXCHANGE_CODE_SUCCESS =
  'app/user/FETCH_EXCHANGE_CODE_SUCCESS';
export const FETCH_EXCHANGE_CODE_ERROR = 'app/user/FETCH_EXCHANGE_CODE_ERROR';

export const UPDATE_SELECTED_CURRENCY_REQUEST =
  'app/user/UPDATE_SELECTED_CURRENCY_REQUEST';
export const UPDATE_SELECTED_CURRENCY_SUCCESS =
  'app/user/UPDATE_SELECTED_CURRENCY_SUCCESS';
export const UPDATE_SELECTED_CURRENCY_ERROR =
  'app/user/UPDATE_SELECTED_CURRENCY_ERROR';

const initialState = {
  selectedCurrency: null,
  fetchExchangeCodeInProgress: false,
  fetchExchangeCodeError: null,
  selectedCurrencyExchangeCode: 1,
};

export const fetchExchangeCodeRequest = () => ({
  type: FETCH_EXCHANGE_CODE_REQUEST,
});

export const fetchExchangeCodeSuccess = payload => ({
  type: FETCH_EXCHANGE_CODE_SUCCESS,
  payload,
});

export const fetchExchangeCodeError = e => ({
  type: FETCH_EXCHANGE_CODE_ERROR,
  error: true,
  payload: e,
});

export const updateSelectedCurrencyRequest = () => ({
  type: UPDATE_SELECTED_CURRENCY_REQUEST,
});

export const updateSelectedCurrencySuccess = data => ({
  type: UPDATE_SELECTED_CURRENCY_SUCCESS,
  payload: data,
});

export const updateSelectedCurrencyError = e => ({
  type: UPDATE_SELECTED_CURRENCY_ERROR,
  error: true,
  payload: e,
});

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_EXCHANGE_CODE_REQUEST:
      return {
        ...state,
        fetchExchangeCodeInProgress: true,
        fetchExchangeCodeError: null,
      };
    case FETCH_EXCHANGE_CODE_SUCCESS:
      return {
        ...state,
        fetchExchangeCodeInProgress: false,
        currencyExchangeCode: payload,
      };
    case FETCH_EXCHANGE_CODE_ERROR:
      return {
        ...state,
        fetchExchangeCodeInProgress: false,
        fetchExchangeCodeError: payload,
      };

    case UPDATE_SELECTED_CURRENCY_REQUEST:
      return {
        ...state,
        updateSelectedCurrencyInProgress: true,
        updateSelectedCurrencyError: null,
      };
    case UPDATE_SELECTED_CURRENCY_SUCCESS:
      return {
        ...state,
        selectedCurrency: payload.selectedCurrency,
        selectedCurrencyExchangeCode: payload.selectedCurrencyExchangeCode,
        updateSelectedCurrencyInProgress: false,
      };
    case UPDATE_SELECTED_CURRENCY_ERROR:
      return {
        ...state,
        updateSelectedCurrencyInProgress: false,
        updateSelectedCurrencyError: payload,
      };
    default:
      return state;
  }
}

export const updateSelectedCurrency = data => (dispatch, getState) => {
  if (
    typeof window !== 'undefined' &&
    data &&
    data.selectedCurrency &&
    data.selectedCurrencyExchangeCode
  ) {
    window.localStorage.setItem('selectedCurrency', data.selectedCurrency);
    window.localStorage.setItem(
      'selectedCurrencyExchangeCode',
      data.selectedCurrencyExchangeCode
    );
  }
  dispatch(updateSelectedCurrencySuccess(data));
};

export const fetchExchangeCode = data => (dispatch, getState) => {
  dispatch(fetchExchangeCodeRequest());

  return axios
    .get(`${apiBaseUrl()}/api/currency/latestExchangeCode`)
    .then(response => {
      if (typeof window !== 'undefined') {
        const selectedCurrency =
          data || window.localStorage.getItem('selectedCurrency');
        if (selectedCurrency) { 

          dispatch(
            updateSelectedCurrency({
              selectedCurrency: selectedCurrency,
              selectedCurrencyExchangeCode:
                response.data.rates[selectedCurrency],
            })
          );
        } else {
          dispatch(
            updateSelectedCurrency({
              selectedCurrency: 'AUD',
              selectedCurrencyExchangeCode: response.data.rates['AUD'],
            })
          );
        }
      }
      dispatch(fetchExchangeCodeSuccess(response.data));
    })
    .catch(err => dispatch(fetchExchangeCodeError(err)));
};
