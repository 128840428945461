import React, { Component, useRef } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { FiInfo } from 'react-icons/fi';
import { ReactComponent as ViewIcon } from '../../assets/Eye.svg';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  NamedLink,
  SearchMap,
} from '../../components';
import { ReactComponent as User } from '../../assets/User.svg';
import axios from 'axios';

import { locationBounds } from '../../components/LocationAutocompleteInput/GeocoderMapbox';
import { types as sdkTypes } from '../../../src/util/sdkLoader';

const { LatLng } = sdkTypes;

import css from './ProfileSettingsForm.module.css';
import { apiBaseUrl } from '../../util/api';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

const ABOUT_MAX_LENGTH = 100;

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
      infoView: false,
      userNameInfoView: false,
      locationInfoView: false,
      isUserNameAvailable: true,
      userNameAvailabilityCheckInProgress: false,
    };
    this.submittedValues = {};
    this.origin = React.createRef(null);
    this.bounds = React.createRef(null);
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
            touched,
            errors,
            active,
            initialValues,
            userId,
          } = fieldRenderProps;
          // console.log(963, userId);

          const user = ensureCurrentUser(currentUser);
          const ensuredUsersUserName =
            user?.attributes?.profile?.publicData?.userName;

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(
            firstNameRequiredMessage
          );
          const userNameRequired = validators.required(
            'This field is required'
          );
          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);
          const locationRequired = validators.required(
            'This feild is required.'
          );
          // Bio
          const bioLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.bioLabel',
          });
          const bioPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.bioPlaceholder',
          });

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({
            [css.avatarUploadError]: hasUploadError,
          });
          const transientUserProfileImage =
            profileImage.uploadedImage || user.profileImage;
          const transientUser = {
            ...user,
            profileImage: transientUserProfileImage,
          };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload =
            profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  {/* <FormattedMessage id="ProfileSettingsForm.changeAvatar" /> */}
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <User className={css.UserSvg} />
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit =
            submittedOnce &&
            isEqual(values, this.submittedValues) &&
            this.state.isUserNameAvailable;
          const submitDisabled =
            invalid ||
            pristine ||
            pristineSinceLastSubmit ||
            uploadInProgress ||
            submitInProgress;

          const aboutYouLabel = 'About You';
          const aboutYouPlaceholder = 'Write Something About Yourself';

          const aboutYouValidator = validators.maxLength(
            'Maximum character count is 100.',
            ABOUT_MAX_LENGTH
          );
          const displayNameInfo =
            'If you represent an organization, you can use its name as your display name. Display name is shown to other users instead of your first and last name.';
          const userNameInfo =
            'Your username is used as part of the unique URL of your profile page. You can only use letters and numbers for your username, without spaces. When you change your username, your URL will automatically change and your previous URL will not be redirected. The old username will become available for other users.';

          const locationInfo =
            'You can provide either your street address or only a city or zip/postal code. It’s good to also add your country when adding your location. Examples: "10117 Berlin, Germany" or "2000 Sand Hill Road, CA, USA".';
          // console.log(85258, this.state.postalCode);
          // const getAddress = async zipCode => {
          //   if (zipCode.length > 4) {
          //     try {
          //       const { results } = (await axios.get(
          //         `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&sensor=true&key=AIzaSyBjPOTitKxb-gjGCC2ha9MMD8g9WmezA_k`
          //       )).data;
          //       console.log(123, results);
          //       // const currCity = getCity(results[0]?.address_components);
          //       // const currArea = getArea(results[0]?.address_components);
          //       // const currCountry = getCountry(results[0]?.address_components);
          //       // const currCountryAsObject = defaultCountryOptions.find(
          //       //   c => c.label === currCountry
          //       // );
          //       const originBounds = new LatLng(
          //         results[0]?.geometry.location.lat,
          //         results[0]?.geometry.location.lng
          //       );
          //       console.log(898, originBounds);
          //       origin.current = originBounds;
          //       bounds.current = locationBounds(org, 1000);
          //       console.log(321, bounds.current, origin.current);
          //       // newListing.current.attributes.geolocation = new LatLng(
          //       //   origin.current.lat,
          //       //   origin.current.lng
          //       // );

          //       // setLat(results[0]?.geometry.location.lat);
          //       // setLng(results[0]?.geometry.location.lng);
          //       // setIsCompleteAddress(!!address);
          //       // if (!address) setAddress(results[0]?.formatted_address);
          //       // setCity(currCity);
          //       // setArea(currArea);
          //       // if (currCountryAsObject) {
          //       //   setCountry(currCountryAsObject);
          //       //   form.change('country', currCountryAsObject?.value);
          //       // }

          //       // form.change('city', currCity);
          //       // form.change('lat', results[0].geometry.location.lat);
          //       // form.change('lng', results[0].geometry.location.lng);
          //       // form.change('address', results[0].formatted_address);
          //       // setSearchText(results[0].formatted_address);
          //     } catch (error) {
          //       console.log(897, error);
          //       // setCity(null);
          //       // setArea(null);
          //     }
          //   } else {
          //     console.log(852159);
          //     // setCity(null);
          //     // setArea(null);
          //   }
          // };
          return (
            <Form
              className={classes}
              onSubmit={e => {
                e.preventDefault();
                this.submittedValues = values;
                if (values.userName === initialValues.userName) {
                  return handleSubmit(e);
                }
                axios
                  .get(
                    `${apiBaseUrl()}/api/checkAvailabilityOfUserName/${
                      values.userName
                    }`
                  )
                  .then(resp => {
                    if (resp?.status === 200) {
                      this.setState({ isUserNameAvailable: true });
                    }
                    return handleSubmit(e);
                  })
                  .catch(err => {
                    if (err?.response?.status === 409) {
                      this.setState({ isUserNameAvailable: false });
                    }
                    return;
                  });
              }}
            >
              {userId ? (
                <div className={css.viewProfile}>
                  <span>
                    <ViewIcon className={css.viewIcon} />
                    <NamedLink
                      className={css.profileLink}
                      name={
                        ensuredUsersUserName ? 'UserProfilePage' : 'ProfilePage'
                      }
                      params={
                        ensuredUsersUserName
                          ? { slug: ensuredUsersUserName }
                          : { id: user.id.uuid }
                      }
                    >
                      <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                    </NamedLink>
                  </span>
                </div>
              ) : null}
              <div className={css.sectionContainer}>
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const {
                      accept,
                      id,
                      input,
                      label,
                      disabled,
                      uploadImageError,
                    } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
                <div className={css.tip}>
                  <div className={css.sectionTitle}>
                    {profileImage?.imageId ? (
                      <span className={css.titleBlue}>Change</span>
                    ) : (
                      <span className={css.titleBlue}>Upload</span>
                    )}

                    <span className={css.title}> your profile picture.</span>
                  </div>
                  <div className={css.fileInfo}>
                    <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                  </div>
                </div>
              </div>

              <div className={css.nameContainer}>
                <FieldTextInput
                  // className={css.firstName}
                  className={classNames(css.inputs, {
                    [css.invalidInputs]:
                      touched.firstName && !!errors.firstName,
                    [css.fnNonEmptyInputs]:
                      !!values.firstName || active === 'firstName',
                  })}
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={firstNameLabel}
                  // placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  // className={css.lastName}
                  className={classNames(css.inputs, {
                    [css.invalidInputs]: touched.lastName && !!errors.lastName,
                    [css.fnNonEmptyInputs]:
                      !!values.lastName || active === 'lastName',
                  })}
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>

              <div className={css.divContainer}>
                <FieldTextInput
                  className={classNames(css.inputs, {
                    [css.invalidInputs]:
                      touched.companyName && !!errors.companyName,
                    [css.fnNonEmptyInputs]:
                      !!values.companyName || active === 'companyName',
                  })}
                  type="text"
                  id="companyName"
                  name="companyName"
                  label="Display Name (Optional)"
                />
                <div className={css.infoDiv}>
                  <FiInfo className={css.infoIcon} />
                  <span className={css.infoPara}>
                    {this.state.infoView ? (
                      displayNameInfo
                    ) : (
                      <span>{displayNameInfo}</span>
                    )}
                    {/* {this.state.infoView
                      ? displayNameInfo
                      : `${displayNameInfo.substring(0, 115)}...`} */}
                    {this.state.infoView ? (
                      <button
                        className={css.moreLess}
                        type="button"
                        onClick={() => {
                          this.setState(state => ({
                            infoView: !this.state.infoView,
                          }));
                        }}
                      >
                        Less
                      </button>
                    ) : (
                      <button
                        className={css.moreLess}
                        type="button"
                        onClick={() => {
                          this.setState({
                            infoView: !this.state.infoView,
                          });
                        }}
                      >
                        More
                      </button>
                    )}
                  </span>
                </div>
              </div>

              <div className={css.divContainer}>
                <FieldTextInput
                  className={classNames(css.inputs, {
                    [css.invalidInputs]: touched.userName && !!errors.userName,
                    [css.fnNonEmptyInputs]:
                      !!values.userName || active === 'userName',
                  })}
                  type="text"
                  id="userName"
                  name="userName"
                  label="User Name*"
                  validate={userNameRequired}
                  onChange={e => {
                    form.change(
                      'userName',
                      e.target.value.replace(/\s/g, '').toLowerCase()
                    );
                  }}
                  onBlur={async e => {
                    if (initialValues.userName !== values.userName) {
                      this.setState({
                        userNameAvailabilityCheckInProgress: true,
                      });
                      axios
                        .get(
                          `${apiBaseUrl()}/api/checkAvailabilityOfUserName/${
                            e.target.value
                          }`
                        )
                        .then(resp => {
                          this.setState({
                            userNameAvailabilityCheckInProgress: false,
                          });
                          if (resp?.status === 200) {
                            this.setState({ isUserNameAvailable: true });
                          }
                        })
                        .catch(err => {
                          this.setState({
                            userNameAvailabilityCheckInProgress: false,
                          });
                          if (err?.response?.status === 409) {
                            this.setState({ isUserNameAvailable: false });
                          }
                        });
                    }
                  }}
                />
                {!this.state.isUserNameAvailable && (
                  <span className={css.errMsg}>UserName not available</span>
                )}
                <div className={css.infoDiv}>
                  <FiInfo className={css.infoIcon} />
                  <span className={css.infoPara}>
                    {this.state.userNameInfoView ? (
                      userNameInfo
                    ) : (
                      <span>{userNameInfo}</span>
                    )}
                    {/* {this.state.userNameInfoView
                      ? userNameInfo
                      : `${userNameInfo.substring(0, 115)}...`} */}
                    {this.state.userNameInfoView ? (
                      <button
                        className={css.moreLess}
                        type="button"
                        onClick={() => {
                          this.setState(state => ({
                            userNameInfoView: !this.state.userNameInfoView,
                          }));
                        }}
                      >
                        Less
                      </button>
                    ) : (
                      <button
                        className={css.moreLess}
                        type="button"
                        onClick={() => {
                          this.setState(state => ({
                            userNameInfoView: !this.state.userNameInfoView,
                          }));
                        }}
                      >
                        More
                      </button>
                    )}
                  </span>
                </div>
              </div>
              {/* <div className={css.locationWrapper}>
                <div className={css.locationDiv}>
                  <FieldTextInput
                    className={css.locationText}
                    type="text"
                    name="shippingAddress"
                    id="shippingAddress"
                    label={'Location*'}
                    placeholder={'Enter your location'}
                    validate={locationRequired}
                    onChange={async e => {
                      const val = e.target.value;
                      this.setState(state => ({
                        postalCode: val.toUpperCase(),
                      }));

                      form.change('shippingAddress', val.toUpperCase());
                      if (val.length === 6) {
                        getAddress(val);
                      }
                      form.change('shippingAddress', e.target.value);
                      const res = await axios.get(
                        `https://maps.googleapis.com/maps/api/geocode/json?address=${e.target.value}&sensor=true&key=AIzaSyBjPOTitKxb-gjGCC2ha9MMD8g9WmezA_k`
                      );
                      console.log(741, res);
                    }}
                  />
                  <SearchMap
                  // bounds={bounds.current}
                  // center={origin.current}
                  />
                </div>
                <div className={css.infoDiv}>
                  <FiInfo className={css.infoIcon} />
                  <span className={css.infoPara}>
                  <span>{locationInfo}</span>
                    // {this.state.locationInfoView
                    //   ? locationInfo
                    //   : `${locationInfo.substring(0, 115)}...`}
                    {this.state.locationInfoView ? (
                      <button
                        className={css.moreLess}
                        type="button"
                        onClick={() => {
                          this.setState(state => ({
                            locationInfoView: !this.state.locationInfoView,
                          }));
                        }}
                      >
                        Less
                      </button>
                    ) : (
                      <button
                        className={css.moreLess}
                        type="button"
                        onClick={() => {
                          this.setState(state => ({
                            locationInfoView: !this.state.locationInfoView,
                          }));
                        }}
                      >
                        More
                      </button>
                    )}
                  </span>
                </div>
              </div> */}
              <div className={css.textareaDiv}>
                <FieldTextInput
                  className={css.textInput}
                  type="textarea"
                  name="bio"
                  id="bio"
                  label={aboutYouLabel}
                  placeholder={aboutYouPlaceholder}
                  // validate={aboutYouValidator}
                  maxLength="500"
                />
                <span className={css.charCount}>
                  Maximum character count is 500.
                </span>
              </div>

              {submitError}
              <div className={css.btnDiv}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={pristineSinceLastSubmit}
                >
                  <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.companyName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
