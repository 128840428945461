import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, Modal, NamedLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './EditListingDescriptionPanel.module.css';
import {
  AUTO_STOCK,
  deliveryMethodOptions,
  platformShippingSupportedCountryCode,
  productTypeCategories,
  returnPolicyOptions,
} from '../../marketplace-custom-config';
import { isEqual, isNumber } from 'lodash';
import { unitDivisor } from '../../util/currency';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import {
  fetchExchangeCode,
  updateSelectedCurrency,
} from '../../ducks/currencyExchangeCode.duck';
const { Money } = sdkTypes;

const getSameParcelDetailsForAllSizes = (sameDetailsForAll, parcelDetails) => {
  const hasDetails =
    sameDetailsForAll &&
    sameDetailsForAll.length > 0 &&
    sameDetailsForAll[0] &&
    parcelDetails &&
    Object.values(parcelDetails)[0];
  if (!hasDetails) {
    return {};
  }
  const {
    depth,
    lngth,

    wght,
    width,
  } = hasDetails[0];

  return {
    parcelLength: lngth,
    parcelWidth: width,
    parcelDepth: depth,
    parcelWght: wght,
  };
};

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling,
    myListingLink,
    onCurrencyChange,
    selectedCurrencyExchangeCode,
    selectedCurrency,
    currencyExchangeCode,
  } = props;
  const [isReady, setIsReady] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const {
    description,
    title,
    publicData,
    privateData,
  } = currentListing.attributes;

  const isPublished =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            {/* <FormattedMessage id="EditListingDescriptionPanel.listingTitle" /> */}
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const certificateOptions = findOptionsForSelectFilter(
    'certificate',
    config.custom.filters
  );

  const clothingStockPerSizeMayBe =
    publicData && publicData.clothingStockPerSize;

  const shoeStockPerSizeMayBe = publicData && publicData.shoeStockPerSize;

  const currentStockRaw = currentListing?.currentStock?.attributes?.quantity;
  // const currentStock =
  //   typeof currentStockRaw != null || typeof currentStockRaw != 'undefined'
  //     ? currentStockRaw
  //     : 1;
  const oldProductCategory = publicData && publicData.productCategory;

  const oldShoeSize = publicData && publicData.shoeSize;
  const oldClothingSize = publicData && publicData.clothingSize;
  const oldClothingStockPerSize = publicData && publicData.clothingStockPerSize;
  const oldShoeStockPerSize = publicData && publicData.shoeStockPerSize;

  const shippingCharge =
    publicData && publicData.shippingCharge ? publicData.shippingCharge : null;
  const shippingChargeAsMoney = shippingCharge
    ? new Money(shippingCharge.amount, shippingCharge.currency)
    : null;
  const additionalItems =
    publicData && publicData?.additionalItems
      ? publicData?.additionalItems
      : null;
  const additionalItemsAsMoney = additionalItems
    ? new Money(additionalItems.amount, additionalItems.currency)
    : null;
  const internationalShippingCharge =
    publicData && publicData?.internationalShippingCharge
      ? publicData?.internationalShippingCharge
      : null;
  const internationalShippingChargeAsMoney = internationalShippingCharge
    ? new Money(
        internationalShippingCharge.amount,
        internationalShippingCharge.currency
      )
    : null;
  const specialPrice =
    publicData && publicData?.specialPrice ? publicData?.specialPrice : null;
  const specialPriceAsMoney = specialPrice
    ? new Money(specialPrice.amount, specialPrice.currency)
    : null;

  const allowCustomQuote =
    publicData && publicData?.allowCustomQuote
      ? publicData?.allowCustomQuote
      : null;

  const {
    isCustomQuote = false,
    customerEmail = null,
    priceSelector,
    priceAsPerCurrency,
    returnPolicy,
    customPolicyDesc,
  } = publicData || {};

  const automaticShippingOption =
    publicData && publicData.automaticShippingOption;
  const parcelDetails = privateData && privateData.parcelDetails;
  const shippingType = publicData && publicData.shippingType;
  const sameDetailsForAll =
    publicData && (publicData.sameDetailsForAll || ['1']);
  const {
    parcelLength,
    parcelDepth,
    parcelWght,
    parcelWidth,
  } = getSameParcelDetailsForAllSizes(sameDetailsForAll, parcelDetails);

  const { price } = currentListing.attributes;

  return (
    <div className={classes}>
      <div className={css.titleDiv}>
        <h1 className={css.title}>{panelTitle}</h1>

        {myListingLink}
      </div>
      {/* {isPublished ? (
        <div className={css.loginTitle}>
          <span className={css.titleBlue}>Edit</span>
          <span className={css.titleBlack}> Listing Details</span>
        </div>
      ) : (
        <div className={css.loginTitle}>
          <span className={css.titleBlue}>Listing</span>
          <span className={css.titleBlack}> Details</span>
        </div>
      )} */}
      <span className={css.hDivider} />
      <p className={css.tip}>
        Please fill out the product details below. Tell your customer about your
        product which tend to answer your buyer question and have a better
        chance to sell your product more.
      </p>
      {console.log(8877, returnPolicy)}
      {/* {!updateInProgress && ( */}
      <EditListingDescriptionForm
        className={css.form}
        isReady={isReady}
        initialValues={{
          title,
          description,
          price,
          certificate: publicData.certificate,
          productCategory: publicData.productCategory,
          productSubCategory: publicData.productSubCategory,
          listingType: publicData.listingType,
          clothingSize: publicData.clothingSize ?? [],
          shoeSize: publicData.shoeSize ?? [],
          requirements: publicData.requirements,
          dinningType: publicData.dinningOptions ?? [],
          accommodationFeatures: publicData.accommodationFeatures ?? [],
          bedroomCount: publicData.bedroomCount,
          bathroomCount: publicData.bathroomCount,
          clothingStockPerSize: clothingStockPerSizeMayBe,
          shoeStockPerSize: shoeStockPerSizeMayBe,
          deliveryMethod: publicData.deliveryMethod ?? [],
          shippingChargeMethod: publicData.shippingChargeMethod ?? [],
          shippingCharge: shippingChargeAsMoney,
          additionalItems: additionalItemsAsMoney,
          internationalShippingCharge: internationalShippingChargeAsMoney,
          allowCustomQuote,
          specialPrice: specialPriceAsMoney,
          specialPriceReq: publicData.specialPriceReq,
          pricingType: publicData.pricingType ?? [],
          availability: publicData.availability ?? [],
          stock: currentStockRaw != null ? currentStockRaw : null,
          carType: publicData.carType ?? [],
          carModelNumber: publicData.carModelNumber
            ? publicData.carModelNumber
            : null,
          priceSelector,
          returnPolicy,
          customPolicyDesc,
          priceAsPerCurrency,
          priceCustom:
            price &&
            price.amount &&
            isNumber(price.amount) &&
            `A$ ${price.amount / unitDivisor(price.currency)}`,
          // `A$ ${Math.round(price.amount / 100)}.00`,
          shippingChargeCustom:
            shippingChargeAsMoney &&
            shippingChargeAsMoney.amount &&
            isNumber(shippingChargeAsMoney.amount) &&
            `A$ ${shippingChargeAsMoney.amount /
              unitDivisor(shippingChargeAsMoney.currency)}`,
          internationalShippingChargeCustom:
            internationalShippingChargeAsMoney &&
            internationalShippingChargeAsMoney.amount &&
            isNumber(internationalShippingChargeAsMoney.amount) &&
            `A$ ${internationalShippingChargeAsMoney.amount /
              unitDivisor(internationalShippingChargeAsMoney.currency)}`,
          specialPriceCustom:
            specialPriceAsMoney &&
            specialPriceAsMoney.amount &&
            isNumber(specialPriceAsMoney.amount) &&
            `A$ ${specialPriceAsMoney.amount /
              unitDivisor(specialPriceAsMoney.currency)}`,
          automaticShippingOption,
          parcelDetails,
          shippingType,
          productHscode: publicData && publicData.productHscode,
          sameDetailsForAll,
          parcelLength,
          parcelDepth,
          parcelWght,
          parcelWidth,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            certificate,
            productCategory,
            productSubCategory,
            listingType,
            clothingSize,
            requirements,
            dinningType,
            accommodationFeatures,
            bedroomCount,
            clothingStockPerSize,
            shoeStockPerSize,
            bathroomCount,
            shoeSize,
            deliveryMethod,
            shippingChargeMethod,
            shippingCharge,
            additionalItems,
            internationalShippingCharge,
            internationalShippingChargeCustom,
            price,
            pricingType,
            stock,
            availability,
            specialPrice,
            specialPriceReq,
            automaticShippingOption,
            parcelDetails,
            shippingType,
            productHscode,
            sameDetailsForAll,
            allowCustomQuote,
            priceSelector,
            priceAsPerCurrency,
            returnPolicy,
            customPolicyDesc,
          } = values;
          const isClothingCategory = productCategory === 'clothing';
          const isShoeCategory = productCategory === 'shoes';
          const isShoeSizeChanged =
            isShoeCategory && !isEqual(shoeSize, oldShoeSize);
          const isClothingSizeChanged =
            isClothingCategory && !isEqual(clothingSize, oldClothingSize);
          const isClothingStockPerSizeChanged =
            isClothingCategory &&
            !isEqual(clothingStockPerSize, oldClothingStockPerSize);
          const isShoeStockPerSizeChanged =
            isShoeCategory && !isEqual(shoeStockPerSize, oldShoeStockPerSize);

          const hasProductCategoryChanged =
            productCategory !== oldProductCategory ||
            isShoeSizeChanged ||
            isClothingSizeChanged ||
            isClothingStockPerSizeChanged ||
            isShoeStockPerSizeChanged;

          const totalStock = hasProductCategoryChanged
            ? isClothingCategory
              ? clothingSize &&
                clothingSize?.reduce((acc, curr) => {
                  const subTotal =
                    clothingStockPerSize && clothingStockPerSize[curr]
                      ? parseInt(clothingStockPerSize[curr])
                      : 0;
                  return acc + subTotal;
                }, 0)
              : isShoeCategory
              ? shoeSize &&
                shoeSize?.reduce((acc, curr) => {
                  const subTotal =
                    shoeStockPerSize && shoeStockPerSize[curr]
                      ? parseInt(shoeStockPerSize[curr])
                      : 0;
                  return acc + subTotal;
                }, 0)
              : stock
            : stock;

          const updateValues = {
            title: title.trim(),
            description,
            price,
            stockUpdate: {
              oldTotal: currentStockRaw != null ? currentStockRaw : null,
              newTotal: AUTO_STOCK.includes(productCategory) ? 500 : totalStock,
            },
            publicData: {
              pricingType,
              certificate,
              productCategory,
              productSubCategory,
              listingType,
              deliveryMethod,
              shippingChargeMethod,
              automaticShippingOption,
              sameDetailsForAll,
              priceSelector,
              priceAsPerCurrency,
              shippingType,
              returnPolicy,
              customPolicyDesc,
              shippingCharge:
                shippingType === deliveryMethodOptions.automaticShipping.value
                  ? null
                  : shippingChargeMethod?.includes('domestic') && shippingCharge
                  ? {
                      amount: shippingCharge.amount,
                      currency: config.currency,
                    }
                  : null,
              additionalItems: additionalItems
                ? {
                    amount: additionalItems?.amount,
                    currency: config.currency,
                  }
                : null,
              internationalShippingCharge:
                shippingType === deliveryMethodOptions.automaticShipping.value
                  ? null
                  : shippingChargeMethod?.includes('international') &&
                    internationalShippingChargeCustom &&
                    internationalShippingCharge
                  ? {
                      amount: internationalShippingCharge?.amount,
                      currency: config.currency,
                    }
                  : null,

              allowCustomQuote,

              specialPrice:
                specialPrice &&
                specialPriceReq &&
                specialPriceReq.includes('yes')
                  ? {
                      amount: specialPrice?.amount,
                      currency: config.currency,
                    }
                  : null,
              specialPriceReq,
              clothingSize:
                clothingSize && clothingSize.length === 0 ? null : clothingSize,
              clothingStockPerSize:
                clothingSize &&
                clothingSize.length &&
                clothingStockPerSize &&
                clothingSize.reduce((acc, curr) => {
                  return {
                    ...acc,
                    [curr]: clothingStockPerSize[curr],
                  };
                }, {}),
              shoeSize: shoeSize && shoeSize.length === 0 ? null : shoeSize,
              shoeStockPerSize: isShoeCategory
                ? shoeSize &&
                  shoeSize.length &&
                  shoeStockPerSize &&
                  shoeSize.reduce((acc, curr) => {
                    return {
                      ...acc,
                      [curr]: shoeStockPerSize[curr],
                    };
                  }, {})
                : null,
              requirements: !requirements ? null : requirements,
              dinningOptions: dinningType.length === 0 ? null : dinningType,
              accommodationFeatures:
                accommodationFeatures.length === 0
                  ? null
                  : accommodationFeatures,
              bedroomCount,
              bathroomCount,
              availability,
              carModelNumber: values.carModelNumber
                ? values.carModelNumber
                : null,
              carType:
                values.carType && values.carType.length === 0
                  ? null
                  : values.carType,
              productHscode,
              isCustomQuote,
            },
            privateData: {
              parcelDetails:
                shippingType === deliveryMethodOptions.automaticShipping.value
                  ? parcelDetails
                  : null,
            },
          };
          onSubmit(updateValues);
          setIsReady(true);
          setTimeout(() => {
            setIsReady(false);
          }, 2000);
        }}
        customerEmail={customerEmail}
        isCustomQuote={isCustomQuote}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        certificateOptions={certificateOptions}
        isPublished={isPublished}
        onManageDisableScrolling={onManageDisableScrolling}
        onCurrencyChange={onCurrencyChange}
        selectedCurrencyExchangeCode={selectedCurrencyExchangeCode}
        selectedCurrency={selectedCurrency}
        currencyExchangeCode={currencyExchangeCode?.rates}
      />
      {/* )} */}
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    selectedCurrencyExchangeCode,
    selectedCurrency,
    currencyExchangeCode,
  } = state.currencyExchangeCode;

  return {
    currentUser,
    selectedCurrencyExchangeCode,
    selectedCurrency,
    currencyExchangeCode,
  };
};

const mapDispatchToProps = dispatch => ({
  onCurrencyChange: data => dispatch(fetchExchangeCode(data)),
  onUpdateSelectedCurrency: data => dispatch(updateSelectedCurrency(data)),
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(EditListingDescriptionPanel));
