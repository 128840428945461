import React, { Component, useState } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import {
  convertUnitToSubUnit,
  formatMoney,
  unitDivisor,
} from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { ResponsiveImage } from '../../components';
import { ReactComponent as LikeButton } from '../../assets/Wishlist.svg';
import { ReactComponent as ProfileIcon } from '../../assets/ProfileIcon.svg';
import css from './ListingCard.module.css';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { withRouter } from 'react-router';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { PriceDataWithDifferentCurrency } from '../../util/PriceDataWithDifferentCurrency';
import SectionAvatar from '../../containers/ListingPage/SectionAvatar';
import { allowedPricingTypeOptions } from '../../marketplace-custom-config';
import SectionReviews from '../../containers/ListingPage/SectionReviews';
const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
  loadAfterInitialRendering: 3000,
});

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    toggleFavouritesButton,
    currentUser,
    isFavourite,
    history,

    location,
    selectedCurrency,
    selectedCurrencyExchangeCode,
    fetchReviewsError,
    reviews,
  } = props;
  const [isHover, setIsHover] = useState(false);
  const isOwnListing = currentUser?.id?.uuid === listing?.author?.id?.uuid;

  const authorName = listing.author.attributes.profile.displayName;
  const companyName =
    listing?.author.attributes.profile.publicData?.companyName;
  // console.log(858, companyName);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;

  const userId = listing.author.id.uuid;

  const certificateOptions = findOptionsForSelectFilter(
    'certificate',
    filtersConfig
  );
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  // const { formattedPrice, priceTitle } = priceData(price, intl);
  const { formattedPrice, priceTitle } =
    PriceDataWithDifferentCurrency(
      price,
      intl,
      selectedCurrency,
      selectedCurrencyExchangeCode
    ) || {};
  //special price
  const specialPrice = listing?.attributes?.publicData?.specialPrice;
  const formattedSpecialPrice =
    specialPrice && specialPrice.amount && specialPrice.currency
      ? PriceDataWithDifferentCurrency(
          new Money(specialPrice.amount, specialPrice.currency),
          intl,
          selectedCurrency,
          selectedCurrencyExchangeCode
        )?.formattedPrice
      : null; 
  
  // console.log(787, formattedSpecialPrice);
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';
  const pricingType = currentListing?.attributes?.publicData?.pricingType;
  const authorImage =
    location.pathname === '/' || location.pathname === '/s'
      ? listing?.author?.profileImage?.attributes?.variants?.default?.url
      : listing?.author?.profileImage?.attributes?.variants?.facebook?.url;

  return (
    <div
      className={classes}
      onClick={() => {
        const params = {
          id,
          slug,
        };
        history.push(
          createResourceLocatorString(
            'ListingPage',
            routeConfiguration(),
            params,
            {}
          )
        );
      }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => {
          setActiveListing(currentListing.id);
        }}
        onMouseLeave={() => {
          setActiveListing(null);
        }}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
        {!isOwnListing ? (
          <div
            className={classNames(css.addToFavDiv, {
              [css.hoverClass]: !isHover,
            })}
          >
            <button
              type="button"
              onClick={e => {
                e.stopPropagation();
                if (!currentUser) {
                  return history.push({
                    pathname: '/login',
                    state: {
                      from: `${location.pathname}${location.search}${location.hash}`,
                    },
                  });
                }
                !!currentUser &&
                  toggleFavouritesButton(currentUser.id.uuid, listing.id.uuid);
              }}
            >
              <LikeButton
                className={classNames(
                  { [css.unfavBtn]: isFavourite },
                  {
                    [css.favBtn]: !isFavourite,
                  }
                )}
              />
            </button>
          </div>
        ) : null}
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>
        {(reviews || []).length > 0 ? (
          <div className={css.ratingDiv}>
            <SectionReviews
              reviews={reviews}
              fetchReviewsError={fetchReviewsError}
              avgReviewSection={true}
              className={css.sectionReviews}
            />
          </div>
        ) : null}
        {!!formattedSpecialPrice &&
        typeof formattedSpecialPrice === 'string' ? (
          <div>
            <div className={css.price}>
              <div className={css.priceValue} title={priceTitle}>
                {formattedSpecialPrice}
              </div>
              <div className={css.perUnit}>
                {
                  allowedPricingTypeOptions.find(
                    ({ value }) => value === pricingType
                  )?.unit
                }
              </div>
              <div className={css.strikedOutPrice}>{formattedPrice}</div>
              {/* {!isOwnListing ? (
                <div className={css.addToFavDiv}>
                  <button
                    type="button"
                    onClick={e => {
                      e.stopPropagation();
                      if (!currentUser) {
                        return history.push({
                          pathname: '/login',
                          state: {
                            from: `${location.pathname}${location.search}${location.hash}`,
                          },
                        });
                      }
                      !!currentUser &&
                        toggleFavouritesButton(
                          currentUser.id.uuid,
                          listing.id.uuid
                        );
                    }}
                  >
                    <LikeButton
                      className={classNames(
                        { [css.unfavBtn]: isFavourite },
                        {
                          [css.favBtn]: !isFavourite,
                        }
                      )}
                    />
                  </button>
                </div>
              ) : null} */}
            </div>
          </div>
        ) : (
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.perUnit}>
              {
                allowedPricingTypeOptions.find(
                  ({ value }) => value === pricingType
                )?.unit
              }
            </div>
            {/* {!isOwnListing ? (
              <div className={css.addToFavDiv}>
                <button
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    if (!currentUser) {
                      return history.push({
                        pathname: '/login',
                        state: {
                          from: `${location.pathname}${location.search}${location.hash}`,
                        },
                      });
                    }
                    !!currentUser &&
                      toggleFavouritesButton(
                        currentUser.id.uuid,
                        listing.id.uuid
                      );
                  }}
                >
                  <LikeButton
                    className={classNames(
                      { [css.unfavBtn]: isFavourite },
                      {
                        [css.favBtn]: !isFavourite,
                      }
                    )}
                  />
                </button>
              </div>
            ) : null} */}
          </div>
        )}
      </div>
      <div className={css.listingAuthor}>
        {!!authorImage ? (
          <>
            <img src={authorImage} alt={''} className={css.profileIcon} />
          </>
        ) : (
          <ProfileIcon className={css.noProfileIcon} />
        )}
        <span>
          {/* {authorName} */}
          {companyName ? companyName : authorName}
        </span>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};
const mapStateToProps = state => {
  const {
    selectedCurrencyExchangeCode,
    selectedCurrency,
    currencyExchangeCode,
  } = state.currencyExchangeCode;
  const { reviews } = state.ListingPage;
  return {
    selectedCurrency,
    selectedCurrencyExchangeCode,
    reviews,
  };
};
const ListingCard = compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(ListingCardComponent);

export default ListingCard;
