import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import MediaQuery from 'react-responsive';
import css from './SectionHomeDecore.module.css';

import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

// import css from './SectionHomeDecor.module.css';
// import left from '../../../assets/boxLeft.svg';
// import right from '../../../assets/boxRight.svg';

// Update images by saving images to src/LandingPage/SeactionFilteredSearches/images directory.
// If those images have been saved with the same name, no need to make changes to these imports.

import { ReactComponent as Heart } from '../../assets/Wishlist.svg';
import { ReactComponent as ProfileIcon } from '../../assets/ProfileIcon.svg';

import Slider from 'react-slick';
import { PrimaryButton } from '../Button/Button';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createSlug } from '../../util/urlHelpers';
import ListingCard from '../ListingCard/ListingCard';
import DemoImage1 from '../../assets/demoImage1.jpg';
import DemoImage2 from '../../assets/demoImage2.jpg';
import DemoImage3 from '../../assets/demoImage3.jpg';
import DemoImage4 from '../../assets/demoImage4.jpg';
import DemoImage5 from '../../assets/demoImage5.jpg';
import DemoImage6 from '../../assets/demoImage6.jpg';
const { Money } = sdkTypes;

// Thumbnail image for the search "card"
class ThumbnailImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
// Load the image only if it's close to viewport (user has scrolled the page enough).
const LazyImage = lazyLoadWithDimensions(ThumbnailImage);

const formItems = (traditionalListings, demoImages, firstText) => {
  const traditionalShownItems = [];
  for (let i = 0; i < 6; i++) {
    traditionalListings?.length >= i
      ? traditionalShownItems.push(traditionalListings[i])
      : traditionalShownItems.push(demoImages[i]);
  }

  traditionalShownItems.unshift({ ftxt: firstText });
  return traditionalShownItems;
};

const createImageUrls = image => {
  const imageVariants = image?.attributes?.variants;
  const variants = ['landscape-crop', 'landscape-crop2x'];
  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      return variant.url;
    })
    .filter(v => v != null);

  return srcSet;
};

// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const SectionHomeDecor = props => {
  const {
    rootClassName,
    className,
    history,
    homeDecorListings,
    intl,
    setActiveListing,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const firstText = (
    <>
      <div className={css.textContainer}>
        {/* <h3 className={css.subheading}>Editors' Picks</h3> */}
        <h2 className={css.heading}>Home decors</h2>
        <p
          className={css.link}
          onClick={() => {
            const params = {
              pub_productCategory: 'homeDecor',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          See more →
        </p>
      </div>
    </>
  );

  const demoImages = [
    { ftxt: firstText },
    { img: DemoImage1 },
    { img: DemoImage2 },
    { img: DemoImage3 },
    { img: DemoImage4 },
    { img: DemoImage5 },
    { img: DemoImage6 },
  ];

  const homeDecorShownItems = formItems(
    homeDecorListings,
    demoImages,
    firstText
  );

  const sideIirstImage =
    homeDecorListings?.[6]?.images && homeDecorListings?.[6]?.images?.length > 0
      ? homeDecorListings?.[6].images[0]
      : null;
  const sideImage = homeDecorListings?.[6]
    ? homeDecorListings?.[6]
    : { img: DemoImage6 };

  const sideUrlSet = sideIirstImage && createImageUrls(sideIirstImage);

  const sideTitle = homeDecorListings?.[6]?.attributes?.title;
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: homeDecorListings.length < 4 ? homeDecorListings.length : 4,

    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow:
            homeDecorListings.length < 4 ? homeDecorListings.length : 3,
          slidesToScroll: 3,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow:
            homeDecorListings.length < 4 ? homeDecorListings.length : 2,
          slidesToScroll: 2,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow:
            homeDecorListings.length < 4 ? homeDecorListings.length : 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
    ],
  };

  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")

  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  return (
    <div className={classes}>
      {/* <div className={css.title}>Home Decor</div> */}
      {/* <div className={`${css.filteredSearches} filteredSearches`}>
        <MediaQuery minWidth={768}>
          <Slider {...settings}>
            {homeDecorListings?.map((l, indx) => {
              {
                return (
                  <ListingCard
                    className={css.listingCard}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    history={history}
                  />
                );
              }
            })}
          </Slider>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          {homeDecorListings.length > 4
            ? homeDecorListings
                .map(l => {
                  return (
                    <ListingCard
                      className={css.searchLink}
                      key={l.id.uuid}
                      listing={l}
                      renderSizes={cardRenderSizes}
                      setActiveListing={setActiveListing}
                      history={history}
                    />
                  );
                })
                .slice(0, 4)
            : homeDecorListings.map(l => {
                return (
                  <ListingCard
                    className={css.searchLink}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    history={history}
                  />
                );
              })}
        </MediaQuery>
      </div> */}
      {/* <h3 className={css.mobileSubheading}>Editors' Picks</h3> */}
      <h2 className={css.mobileHeading}>Home decors</h2>
      <p
        className={css.mobileLink}
        onClick={() => {
          const params = {
            pub_productCategory: 'homeDecor',
          };
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              params
            )
          );
        }}
      >
        See more →
      </p>
      <div className={css.container}>
        <div className={css.fChild}>
          {homeDecorShownItems.map((item, index) => {
            const title = item?.attributes?.title;
            const id = item?.id?.uuid;
            const firstImage =
              item.images && item.images.length > 0 ? item.images[0] : null;

            const urlSet = firstImage && createImageUrls(firstImage);
            return (
              <>
                {item?.ftxt && item?.ftxt}
                {item?.img && (
                  <div key={index} className={css.galleryItem}>
                    <img src={item.img} alt={item.alt} className={css.image} />
                  </div>
                )}
                {item?.type === 'listing' && (
                  <div className={css.galleryItem}>
                    <img
                      src={urlSet[0]}
                      alt={title}
                      className={css.image}
                      onClick={() => {
                        const params = {
                          id,
                          slug: title,
                        };
                        history.push(
                          createResourceLocatorString(
                            'ListingPage',
                            routeConfiguration(),
                            params,
                            {}
                          )
                        );
                      }}
                    />
                  </div>
                )}
              </>
            );
          })}
        </div>
        <div className={css.sChild}>
          {sideImage?.img && (
            <img
              src={sideImage.img}
              alt={sideImage.alt}
              className={css.image}
            />
          )}
          {sideImage?.type === 'listing' && (
            <img src={sideUrlSet[0]} alt={sideTitle} className={css.image} />
          )}
        </div>
      </div>

      {/* <div className={css.browseBtnDiv}>
        <PrimaryButton
          className={css.browseBtn}
          onClick={e => {
            const params = {
              pub_productCategory: 'homeDecor',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          Browse All
        </PrimaryButton>
      </div> */}
    </div>
  );
};

SectionHomeDecor.defaultProps = { rootClassName: null, className: null };

SectionHomeDecor.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHomeDecor;
